@use '../../abstracts' as *;

// video card
.video-card {
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    .video-card__media {
        @extend %buttonreset;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        z-index: 1;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &.hidden {
            display: none;
        }
    }

    .video-card__icon {
        width: 60px;
        height: 60px;
        background: rgba(203, 96, 21, .86);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    .video-card__icon-svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: $color-white;
    }

    .video-card__video {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            border: none;
        }
    }
}

// video carousel card
.video-carousel-card {
    .video-carousel-card__media {
        position: relative;
        height: 180px;
    }

    .video-carousel-card__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .video-carousel-card__tag {
        background: $color-deep-orange;
        color: $color-white;
        padding: 8px 30px;
        @include x-rem(font-size, 13px);
        text-transform: uppercase;
        letter-spacing: .16px;
    }

    .video-carousel-card__type {
        @include x-rem(font-size, 12px);
        text-transform: uppercase;
        letter-spacing: .16px;
        font-weight: $fontWeight-extra-bold;
    }

    .video-carousel-card__icon {
        width: 60px;
        height: 60px;
        background: rgba(203, 96, 21, .86);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .video-carousel-card__icon-svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: $color-white;
    }
}

// media queries 
@media(min-width:$bp-l) {
    .video-carousel-card {
        .video-carousel-card__media {
            height: 260px;
        }
    }
}

@media(min-width:$bp-l) {
    .video-card {
        .video-card__icon {
            width: 86px;
            height: 86px;
        }

        .video-card__icon-svg {
            width: 2.5rem;
            height: 2.5rem;
        }
    }
}