@use '../../abstracts' as *;

.icon-card {
    --color-link:var(--color-white);
    position: relative;
    border-radius: 8px;
    background: #005994;
    overflow: hidden;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    padding: 1.875rem 2.5rem 2.8125rem;
    border: 1px solid #0062A3;
    text-align: center;
}

.icon-card__title,
.icon-card__sub-title,
.icon-card__content {
    color: $color-white;
}

.icon-card__title {
    @extend %item__title;
    margin-bottom: 1rem;
    @include x-rem(font-size, 20px);
}

.icon-card__content {
    line-height: 25.6px;
    margin-bottom: 1rem;
}

.icon-card__media {
    margin-bottom: 1rem;
}

.icon-card__actions {
    gap: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: auto;
}

[class*="col-"] {
    .icon-card {
        position: relative;
        height: 100%;
    }
}

@media(min-width:$bp-m) {
    .icon-card__title {
        @include x-rem(font-size, 24px);
    }
}