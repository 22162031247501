@use '../abstracts' as *;

.site-header {
    position:relative;
    z-index: 100;
    background: var(--color-white);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.10);
}

.header-inner {
    max-width:1800px;
    margin:0 auto;
    padding:14px clamp(20px, 3.6vw, 60px);
}

.header-layout {
    display: flex;
    column-gap:48px;
    justify-content: space-between;
}

.header-col--fdic {
    width:100%;
}

.header-col--logo {
    a {
        display: inline-block;
        text-decoration: none;
    }

    img {
        max-width:200px;
    }
}

.header-util {
    display:none;
}

#headerLogin {
    position:absolute;
    top:100%;
    right:0;
    min-width:100%;
    max-width:320px;
    border-radius: 5px;
    padding:2px;
    background: $color-white;
    box-shadow: 0px 6px 16px 0px rgba(2, 33, 53, 0.20);
    white-space: nowrap;

    [role="tab"]:first-child {
        border-top-left-radius: 5px;
    }

    [role="tab"]:last-child {
        border-top-right-radius: 5px;
    }
}

.header-login__toggle {
    @extend %buttonreset;
    color:var(--color-blue);

    .icon {
        width:23px;
        height: 23px;
        fill:currentColor;
    }
}

@media(min-width:$bp-l){
    .header-login__toggle {
        display:inline-flex;
        align-items: center;
        gap:5px;
        border-radius: 80px;
        border:2px solid currentColor;
        padding:10px 22px;
        font-size:0.9375rem;
        font-weight:$fontWeight-bold;
        white-space: nowrap;

        &:after {
            border-bottom: 2px solid currentColor;
            border-right: 2px solid currentColor;
            content: "";
            display: block;
            height: 7px;
            width: 7px;
            margin-bottom: 3px;
            transform: rotate(45deg);
        }

        &.active {
            background:var(--color-blue);
            color:var(--color-white);
        }
    }
    
    .header-inner {
        padding-top:18px;
        padding-bottom:30px;
        transition:padding .3s ease-in-out;
    }

    .header-col--logo img {
        max-width: 100%;
    }

    .header-col--nav {
        display: flex;
        flex-direction: column;
        gap:20px;
        margin-left:auto;
    }

    .header-util {
        display:flex;
        justify-content: flex-end;
    }

    .header-login {
        position:relative;
    }

    .site-header {
        position:sticky;
        top:0;
        width:100%;

        &.sticky {
            .header-inner {
                padding:20px 14px;
            }

            .header-col--logo img {
                max-height:65px;
            }
        }
    }
}

@media(max-height:48em) {
    .site-header.sticky {
        position: relative;

        .header-inner {
            padding-top:18px;
            padding-bottom:30px;
        }

        .header-col--logo img {
            max-width: 100%;
        }
    }
}