@use '../abstracts' as *;

.site-search-toggle {
    --icon-size:16px;
    @extend %buttonreset;
    color:$color-primary;

    .icon {
        width:var(--icon-size);
        height:var(--icon-size);
        color:currentColor;
    }
}

.site-search {
    padding:20px;
    background:$color-off-white;
}

.site-search__controls {
    position:relative;
}

.site-search__input {
    padding-right:42px;   
}

.site-search__submit {
    @extend %buttonreset;
    position:absolute;
    top:0;
    height:100%;
    right:14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:$color-primary;

    .icon {
        width:18px;
        height:18px;
    }
}

@media(max-width:$bp-mw-max){
    .site-search {
        display:block !important;
    }
}

@media(min-width:$bp-l){
    .site-search {
        display:none;
        position: absolute;
        top:100%;
        left:0;
        width:100%;
    }
}