@use '../abstracts' as *;

%button {
    display:inline-flex;
    gap:8px;
    align-items: center;
    justify-content: center;
    padding: 16px 26px;
	border:0;
    border-radius:80px;
	font-family:var(--font-secondary); 
    font-weight:$fontWeight-bold; 
	text-decoration:none; 
    text-align:center;
    background:$color-primary;
    line-height:1;
    color:var(--color-white);
    cursor: pointer;
    will-change: background;
    transition:background $transition-speed ease-out;

    svg {
        fill: currentColor;
    }
}

.button {
	@extend %button;

    &.button--outlined {
        background:transparent;
        border:2px solid $color-primary;
        color:$color-primary;
    }

    &.button--outlined-white {
        background:transparent;
        border:2px solid var(--color-white);
        color:var(--color-white);

        img {
            filter: brightness(1.5) saturate(0.5);
        }
    }

    &.button--ghost {
        background:transparent;
        color:$color-primary;
    }

    &.button--ghost-white {
        background:transparent;
        color:var(--color-white);

        img {
            filter: brightness(1.5) saturate(0.5);
        }
    }

	&:hover, &:focus-visible {
		text-decoration:underline;
	}
}