@use '../../abstracts' as *;

.sm-card {
    border-radius: 8px;
    background: $color-white;
    border: none;
    display: flex;
    gap:1rem;
    padding: 1rem 0;
}

@container (width < 300px) {
    .sm-card {
        flex-direction: column;
    }
}

.sm-card__media {
    flex-shrink:0;
}

.sm-card__img {
    border-radius: 3px;
}

.sm-card__body {
    max-width: 302px;
}

.sm-card__title {
    @extend %item__title;
    @include x-rem(font-size, 20px);
    color: $color-dark-blue;
    font-weight: $fontWeight-extra-bold;
    margin-bottom: .25rem;
}

.sm-card__content {
    color: $color-dark-gray;
    line-height: 22.4px;
    font-family: $font-secondary;
}

.sm-card__actions {
    margin-top: 1rem;
}

@media(min-width:$bp-l) {
    .sm-card__title {
        @include x-rem(font-size, 24px);
        margin-bottom: .5rem;
    }
}