@use '../../abstracts/' as *;

.news-list,
.events-list {
    box-shadow: 0px 6px 24px 0px #0221350F;
    padding: 1.875rem 1.875rem 3rem 1.875rem;
    background: $color-white;
    border-radius: 12px;

    .sf-col & {
        height:100%;
    }

    .card {
        box-shadow: none;
        border-radius: 0;
        padding: 0;
        padding-bottom: 1.875rem;

        &:after {
            display: none;
        }

        &:last-child {
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
        }

        .card__meta {
            color: $color-deep-orange;
            font-weight: $fontWeight-semibold;
            @include x-rem(font-size, 12px);
        }

        .card__title {
            @include x-rem(font-size, 20px);
            font-weight: $fontWeight-extra-bold;
            color:$color-blue;
        }

        .card__summary {
            color: $color-charcoal-gray;
            font-family: $font-secondary;
        }
    }
}

.news-list__heading,
.events-list__heading {
    color: $color-deep-orange;
    font-weight: $fontWeight-semibold;
    text-transform: uppercase;
    @include x-rem(font-size, 16px);
    letter-spacing: .5px;
}

.news-list__more,
.events-list__more {
    @include x-rem(font-size, 18px);
}

@media(min-width: $bp-l) {
    .news-list__heading,
    .events-list__heading {
        @include x-rem(font-size, 18px);
    }
}

// Featuerd Events 
.events-list--featured {
    .card {
        margin-bottom: 1rem;
        container-type: inline-size;
    }

    .card__media {
        max-width:220px;
    }

    .card__body {
        max-width: 355px;
    }

    .card__meta {
        @include x-rem(font-size, 16px);
    }

    .card__title {
        margin-bottom: .5rem;
    }
}

@container (width >=400px) {
    .events-list--featured {
        .card {
            display: flex;
        }
    }
}

@media(min-width: $bp-l) {
    .events-list--featured {
        .card__title {
            @include x-rem(font-size, 24px);
        }
    }
}

// Events List w/ Image
.events-list-container {
    container-type: inline-size;
}

.events-list--image {
    box-shadow: none;
    padding: 0;

    .card__media {
        position: relative;
        height: 180px;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

@container (width >=450px) {
    .events-list--image {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 32px;
    }
}

@container (width >=920px) {
    .events-list--image {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 32px 40px;
    }
}

@media(min-width: $bp-m) {
    .events-list--image {
        .card__media {
            height: 260px;
        }

        .card__meta {
            font-size: 1rem !important;
        }
    }
}