@use '../abstracts' as *;

.main-nav-wrap {
    display: none;
    position:absolute;
    top:100%;
    left:0;
    width:100%;
    background:$color-off-white;
}

.main-nav-toggle {
    @extend %buttonreset;
}

.main-nav-toggle__icon {
    width: 24px;
    height: 18px;
    display: inline-block;
    position: relative;
}

.main-nav-toggle__icon-inner {
    display: block;
    margin-top: 5px;
    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &,
    &::before,
    &::after {
        width: 100%;
        height: 3px;
        background-color: $color-primary;
        border-radius: 4px;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }

    &::before,
    &::after {
        content: "";
        display: block;
    }

    &::before {
        top: 8px * -1;
        transition:transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    &::after {
        bottom: 9px * -1;
        top: 16px * -1;
        transition: transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    
    .active & {
        background:var(--color-white);

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            top:-8px;
            transform: rotate(-45deg);
        }
    }
}

.main-nav__menu,
.main-nav__sub-list {
    @extend %listreset;
}

.main-nav__toggle,
.main-nav-wrap .util-nav__toggle {
    @extend %buttonreset;
    position:relative;
    display: block;
    width: 100%;
    text-align: left;

    &:before,
    &:after {
        @include pseudo();
        background-color: currentColor;
        width: 2px; 
        height: 20px;
        top: 50%;
        right:20px;
    }

    &:before {
        right:29px;
        transform:translateY(-50%);
        opacity: 1;
        transition: opacity 0.3s ease-in-out; 
    }

    &:after {
        width: 20px; 
        height: 2px;
    }

    &.active:before {
        opacity:0;
    }
}

.main-nav__link {
    display: block;
    text-decoration: none;
}

.main-nav__sub,
.main-nav__sub-list,
.main-nav__sub-list-title {
    display:none;
}

// Level 1
.main-nav__menu > .main-nav__item,
.main-nav-wrap .util-nav__menu > .util-nav__item {
    border-top:1px solid #C3D5E0;
    
    & > .main-nav__link,
    & > .main-nav__toggle,
    & > .util-nav__link, 
    & > .util-nav__toggle {
        padding:18px 20px;
        font-size:1.375rem;
        font-weight:$fontWeight-bold;
        line-height:1;
        color:$color-primary;
    }

    & > .util-nav__link, 
    & > .util-nav__toggle {
        display: block;
        text-decoration: none;
        color:var(--color-dark-gray);
    }

    .main-nav__toggle,
    .util-nav__toggle {
        transition:background .3s ease-in-out, color .3s ease-in-out;

        &.active {
            background:$color-primary;
            color:var(--color-white);
        }
    }
}

.main-nav-wrap .util-nav__sub {
    flex-direction: column;
    background:var(--color-light-blue);

    .util-nav__link {
        display: block;
        padding:14px 40px;
        font-size:1.125rem;
        font-weight:$fontWeight-bold;
        text-decoration: none;
        color:$color-primary;
    }
}

//Level 2+
.main-nav__sub {
    background:$color-light-blue;

    .main-nav__sub-inner {
        display: block;
        width:100%;
    }

    & > .main-nav__sub-inner > .main-nav__sub-list {
        display: block;

        & > .main-nav__item {
            & > .main-nav__link,
            & > .main-nav__toggle {
                padding:14px 40px;
                font-size:1.125rem;
                font-weight:$fontWeight-bold;
                color:$color-primary;

                &.active {
                    background:var(--color-dark-blue);
                    color:var(--color-white);
                }
            }
        }
    }

    .main-nav__sub-list {
        .main-nav__sub-list {
            flex-direction: column;
            padding-top:16px;
            padding-bottom:40px;
            padding-left:60px;
            gap:20px;
            background:var(--color-dark-blue);
        }
    
        .main-nav__link,
        .main-nav__toggle {
            font-weight:$fontWeight-semibold;
            color:var(--color-white);
        }
    }
}

.main-nav-wrap .util-nav {
    display:block;
}

@media(min-width:$bp-l){
    .main-nav-wrap {
        position:static;
        display: block !important;
        background:none;
    }

    .main-nav-toggle,
    .main-nav-wrap .util-nav {
        display: none;
    }

    .main-nav__menu {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap:2.5rem;
    }

    // Level 1
    .main-nav__menu > .main-nav__item {
        border-top:0;
        padding-top:0;

        & > .main-nav__link,
        & > .main-nav__toggle {
            padding:0;
            font-size:0.9375rem;
            text-transform: uppercase;
            color:var(--color-blue);
        }

        & > .main-nav__toggle {
            position: relative;
            display: inline-flex;
            align-items: center;
            gap:6px;

            &:before {
                @include pseudo();
                top:calc(100% + 6px);
                left:0;
                width:100%;
                height:2px;
                background:$color-secondary;
                opacity:0;
                transition:opacity 0.3s;
            }

            &:after {
                @include pseudo(block, static, '');
                width:0;
                height:0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 4px solid currentColor;
                background:none;
                transition:transform .3s ease-in-out;
            }

            &.active {
                background:none;
                color:$color-primary;

                &:before {
                    opacity:1;
                }
                &:after {
                    transform:rotate(-180deg);
                }
            }
        }
    }

    // Level 2
    .main-nav__sub {
        position:absolute;
        top:100%;
        left:0;
        z-index: -1;
        width:100%;
        padding:clamp(25px, 2.5vw, 50px) 0 clamp(30px, 3.1vw, 60px);
        background:$color-off-white;

        .main-nav__sub-inner {
            display: grid;
            grid-template-columns:1fr 22.6%;
            gap:50px;
            width:auto;
            max-width:1650px;
            margin:0 auto;
            padding:0 20px;

            & > .main-nav__sub-list {
                display: grid;
                grid-template-columns:repeat(4, minmax(0, 1fr));
                align-self: start;
            }
        }

        .main-nav__toggle {
            display:none;
        }

        .main-nav__sub-list-title {
            display: block;
            margin-bottom:24px;
            font-size:1.25rem;
            font-weight: $fontWeight-bold;
            line-height:1.4;
            color:$color-dark-blue;

            &:after {
                @include pseudo(block, static, '');
                width:50px;
                height: 4px;
                margin-top:16px;
                background:$color-secondary;
            }
        }
    }

    // Level 3
    .main-nav__sub .main-nav__sub-list {
        display: grid;
        gap:18px;
        background:none;

        .main-nav__sub-list {
            padding-block:0;
            padding-left: 0;
            background:none;
        }

        .main-nav__link {
            font-weight:$fontWeight-semibold;
            line-height:1;
            color:$color-primary;
        }

        // Level 4
        .main-nav__sub-list .main-nav__sub-list {
            padding-left:8px;

            .main-nav__link {
                font-size:0.875rem;
                font-weight:$fontWeight-normal;
            }
        }
    }
}

.main-nav {

}