@use '../../abstracts' as *;

.text-card {
    position: relative;
    border-radius: 8px;
    background: $color-white;
    overflow: hidden;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.1);
    padding: 0;

    .sf-col > & {
        height:100%;
        margin-bottom:0;
    }
}

.text-card__body {
    padding: 1.875rem 1.875rem 3rem;
}

.text-card__sub-title {
    color: $color-burnt-orange;
    text-transform: uppercase;
    letter-spacing: .5px;
    font-weight: $fontWeight-medium;
    display: block;
}

.text-card__title {
    @extend %item__title;
    @include x-rem(font-size, 26px);
    position: relative;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;

    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 50px;
        height: 6px;
        background: $color-green;
    }
}

.text-card__content {
    color: $color-dark-gray;
    line-height: 25.6px;
    font-family: $font-secondary;
}

.text-card__actions {
    margin-top: 1rem;
}

.text-card__bottom {
    background: $color-primary;
    color: $color-white;
    padding: 1rem 1.875rem;
}

.text-card__bottom-action {
    color: $color-white;
    font-weight: $fontWeight-semibold;
}

.text-card__bottom-img {
    margin-right: 4px;
}

@media(min-width:$bp-l) {
    .text-card__sub-title {
        @include x-rem(font-size, 18px);
    }

    .text-card__title {
        @include x-rem(font-size, 36px);
    }
}