@use '../abstracts' as *;

[role="tablist"] {
    display: flex;
    justify-content: center
}

[role="tab"] {
    @extend %buttonreset;
    position: relative;
    padding:12px 30px;
    text-align: center;
    font-size:0.875rem;
    font-weight:$fontWeight-bold;
    line-height:1.3;
    letter-spacing: 0.0088rem;

    &:after {
        @include pseudo();
        left:50%;
        bottom: -1px;
        transform:translateX(-50%);
        width: 100%;
        border-top: 4px solid $color-white;
        opacity: 0;
        transition:opacity 0.2s ease-in-out;
    }

    &.active {
        color:var(--color-white);

        &:after {
            opacity: 1;
        }
    }
}

// tab section 
.tabs-list {
    display: none;
}

.tab {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    color: $color-white;
    padding: 16px;
    @include x-rem(font-size, 18px);
    font-weight: $fontWeight-extra-bold;
    color: var(--color-vivid-orange);
}

.tab--mobile {
    width: 100%;
    position: relative;
    border-bottom: 1px solid rgba(214, 214, 214, .5);
    justify-content: flex-start;
    color: var(--color-white);

    &:after {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        border-right: 2px solid $color-white;
        border-bottom: 2px solid $color-white;
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%) rotate(45deg);
    }

    &.active {
        border: none;

        &:after {
            transform: translateY(-50%) rotate(-135deg);
        }
    }
}

.tab__img-filter {
    filter: grayscale(0);
}

.tab__img {
    margin-right: 6px;
}

.tab-panel {
    display: none;
    padding: 16px 0;

    &.active {
        display: block;
    }
}

.sf-zone-widget {
    .tab-panel {
        display: block;
    }
}

@media(min-width: $bp-l) {
    .tab {
        margin: 0 8px;
        @include x-rem(font-size, 20px);

        .tab__img {
            filter: invert(28%) sepia(54%) saturate(4199%) hue-rotate(336deg) brightness(102%) contrast(101%);
        }

        &.active {
            .tab__img {
                filter: none;
            }
        }
    }

    .tab--mobile {
        display: none;
    }

    .tabs-list {
        display: flex;
        border-bottom: 1px solid rgba(214, 214, 214, .5);
    }

    .tab-panel {
        padding: 40px 0;
    }
}