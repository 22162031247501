@use '../../abstracts/' as *;

.checkbox-item label {
    position:relative;
    display: inline-flex;
    gap:8px;
    cursor: pointer;

    &:before {
        @include pseudo(block, static, '');
        width:18px;
        height: 20px;
        border:1px solid #757575;
        flex-shrink:0;
        background:var(--color-white);
    }

    &:after {
        @include pseudo(block, absolute, '\2713');
        top:0;
        left:3px;
        color:$color-primary;
        opacity:0;
        line-height: 1.4;
        transition:opacity .3s ease-in-out;
    }

    &:has(input:checked) label:after {
        opacity: 1;
    }
}

.checkbox-item__input {
    position:absolute;
    top:0;
    left:0;
    width:18px;
    height:20px;
    z-index:-1;
}