@use '../../abstracts/' as *;

.location-detail__main {
    padding: 40px 0;
}

.location-detail__container {
    display: flex;
    flex-direction: column;
}

.location-detail__link {
    &.desktop {
        display: none;
    }

    &.mobile {
        display: inline-block;
        margin-bottom: 32px;
    }
}

.location-detail__title {
    margin-top: 32px;
    @include x-rem(font-size, 26px);
}

.location-detail__img {
    border-radius: 8px;
    display: block;
    margin-bottom: 16px;
}

.location-detail__label {
    display: block;
    color: $color-dark-blue;
    font-weight: $fontWeight-extra-bold;
    @include x-rem(font-size, 20px);
}

.location-detail__address {
    address {
        font-style: normal;
    }
}

.location-detail__phone {
    a {
        font-weight: $fontWeight-normal;
        color: $color-base;
    }
}

.location-detail__address,
.location-detail__phone,
.location-detail__fax {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    img {
        margin-right: 8px;
    }
}

.location-detail__directions {
    display: inline-block;
    margin-bottom: 16px;
}

.location-detail__type {
    margin-left: 16px;
    position: relative;

    &:before {
        content: '';
        display: block;
        height: 13px;
        width: 1px;
        background: $color-base;
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
    }

    &:first-child {
        margin: 0;

        &:before {
            display: none;
        }
    }
}

.location-detail__general-info {
    margin-bottom: 16px;
}

.location-detail__hours {
    background: $color-off-white;
    padding: 40px 0;
}

.location-detail__hours-container {
    margin: 16px 0;
}

.location-detail__hours-info {
    margin-bottom: 20px;

    p {
        margin: 0;
    }
}

.location-detail__schedule {
    background: $color-off-white;
    padding: 40px 0;
    container-type: inline-size;
}

.location-detail__schedule-container {
    margin: 16px 0;
    padding: 16px;

    .button {
        width: 100%;
    }
}

.location-detail__schedule-text {
    margin-bottom: 32px;
}

@container (width > 768px) {
    .location-detail__schedule-container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 32px;

        .button {
            max-width: 270px;
        }
    }

    .location-detail__schedule-title {
        max-width: 300px;
    }

    .location-detail__schedule-text {
        max-width: 590px;
        margin: 0 16px;
    }
}

@media(min-width: $bp-m) {
    .location-detail__container {
        flex-direction: row;
    }

    .location-detail__link {
        &.desktop {
            display: inline-block;
        }

        &.mobile {
            display: none;
        }
    }

    .location-detail__media {
        order: 2;
        width: 48%;
    }

    .location-detail__content {
        padding-right: 40px;
        width: 52%;
    }

    .location-detail__title {
        @include x-rem(font-size, 36px);
    }

    .location-detail__hours-container {
        display: flex;
    }

    .location-detail__hours-info {
        margin-right: 40px;
    }
}

@media(min-width: $bp-l) {
    .location-detail__content {
        padding-right: 80px;
    }

    .location-detail__general-info {
        display: flex;

        div:first-child {
            margin-right: 40px;
        }
    }

    .location-detail__hours-info {
        margin-right: 100px;
    }
}