@use '../../abstracts/' as *;

.login-form {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 6px 16px 0 rgba(2, 33, 53, .2);
    padding: 2px;

    .icon {
        position:absolute;
        top:50%;
        left:10px;
        width:16px;
        height:22px;
        transform:translateY(-50%);
        color:var(--color-blue);
    }

    .tab-panel {
        padding:0;
    }
}

.login-form__tabs,
.ROBOLoginSwitcherOptions {
    display: flex;
    justify-content: stretch;
    & > * {
        flex-grow:1;

        &:first-child {
            border-top-left-radius: 5px;
        }

        &:last-child {
            border-top-right-radius: 5px;
        }
    }
}

.login-form__tab {
    position: relative;
    display: block;
    font-size:0.875rem;
    text-transform: uppercase;
    letter-spacing: .14px;
    line-height:1.3;
    text-transform: uppercase;
    font-weight: $fontWeight-bold;
    text-align: center;
    background:$color-primary;
    color:$color-white;

    &:before {
        @include pseudo();
        top: 15px;
        left: 13px;
        width:12px;
        height:12px;
        border-radius: 50%;
        border:1px solid currentColor;
    }

    &[role="tab"]:after {
        @include pseudo();
        top: 18px;
        left: 16px;
        bottom: auto;
        width: 6px;
        height: 6px;
        border:0;
        border-radius: 50%;
        background: currentColor;
        transform: none;
    }

    &[aria-selected="true"],
    &[role="tab"].active {
        background:$color-white;
        color:$color-primary;

        &:after {
            opacity:1;
        }
    }
}

.login-form__title {
    margin-bottom:4px;
    font-size:1.125rem;
}

.login-form__form {
    display: grid;
    gap:12px;
    padding:20px;
}

.login-form__input {
    position:relative;

    label {
        position:absolute;
        top:0;
        left:36px;
        font-family: var(--font-secondary);
        font-size:10px;
        font-weight:$fontWeight-medium;
        opacity: 0;
        color:var(--color-dark-blue);
        transition:opacity 0.2s ease-in-out;
    }

    .form-control {
        border:0;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom:1px solid var(--color-green);
        padding-left:36px;
        background:#F8FAFC;
        font-weight:$fontWeight-medium;
        color:var(--color-dark-blue);

        &::placeholder {
            color: currentColor;
        }
    }

    &:focus-within:has(.form-control:not(:placeholder-shown)){
        label {
            opacity: 1;
        }
    }
}

.login-form__submit {
    margin-top:6px;
    justify-content: center;
    text-transform: uppercase;
}

.login-form__actions {
    display:flex;
    flex-direction: column;
    padding:10px 20px 18px;
    background: rgba(0, 82, 136, 0.06);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.login-form__link {
    font-size:0.875rem;
    font-weight:$fontWeight-normal;
}