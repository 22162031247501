@use '../../abstracts/' as *;

.accordion-list {
    display: flex;
    flex-direction: column;
}

.accordion-item {
    border-bottom: 1px solid $color-blue;

    &:last-child {
        border: none;
    }
}

.accordion-item__toggle {
    @extend %buttonreset;
    position:relative;
    display: flex;
    gap:16px;
    width: 100%;
    color: $color-blue;
    font-weight: $fontWeight-bold;

    &:after {
        content:'';
        display:block;
        width:12px;
        height:12px;
        border-bottom: 2px solid $color-blue;
        border-left: 2px solid $color-blue;
        transform: translateY(-50%) rotate(-45deg);
        position: absolute;
        top: 50%;
        right: 16px;
    }

    &.active:after {
        transform: translateY(-50%) rotate(135deg);
    }
}

.accordion-item__content {
    display: none;
    color: $color-charcoal-gray;
}

@media(min-width: $bp-l) {
    .accordion-item__toggle {
        @include x-rem(font-size, 24px);
    }

    .accordion-item__content {
        @include x-rem(font-size, 20px);
    }
}

// Carousel List
.container--carousel {
    position: relative;

    .swiper-button-prev,
    .swiper-button-next {
        display: none;
        background: rgba(0, 0, 0, .7);
        height: 50px;
        width: 50px;
        border-radius: 50%;
        padding: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: .2s ease-in-out;

        &:after {
            color: $color-vivid-orange;
        }

        &.swiper-button-disabled {
            opacity: .5;
        }

        &:hover,
        &:focus {
            background: $color-deep-orange;

            &:after {
                color: $color-white;
            }
        }
    }
}