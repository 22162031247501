@use '../../abstracts' as *;

.lg-card {
    position: relative;
    border-radius: 8px;
    background: $color-white;
    overflow: hidden;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.1);
    padding: 0;

    .sf-col > & {
        height:100%;
        margin-bottom:0;
    }
}

.lg-card__body {
    position: relative;
    padding: 1rem;
    container-type: inline-size;
}

.lg-card__title {
    @extend %item__title;
    margin-bottom: .25rem;
    font-size:1.625rem;
}

.lg-card__sub-title {
    display: block;
    position: relative;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    font-size:1.25rem;

    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 50px;
        height: 6px;
        background: $color-green;
    }
}

.lg-card__content {
    color: $color-dark-gray;
    margin-bottom: 1rem;
    font-family: $font-secondary;
}

.lg-card__actions {
    margin-top: 1rem;
}

.lg-card__action {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    flex-shrink: 0;

    img {
        flex-shrink:0;
        margin-right: .5rem;
    }
}

.lg-card__media {
    position:relative;
    display: grid;
    overflow: hidden;

    &:after {
        @include pseudo();
        top:0;
        left:0;
        right:0;
        bottom:0;
        background: linear-gradient(180deg, rgba(1, 25, 41, 0.00) 55.78%, rgba(0, 12, 19, 0.6) 77.21%, rgba(0, 12, 19, 0.8) 99.81%);
    }

    & > * {
        grid-area:1/1;
    }

    .lg-card__title {
        position: relative;
        z-index: 1;
        left:30px;
        bottom:30px;
        align-self: end;
        color:$color-white;
    }
}

.lg-card__img {
    width: 100%;
    max-height: 330px;
    object-fit: cover;
}

@container (width >=535px) {
    .lg-card__actions {
        display: flex;
    }

    .lg-card__action {
        margin-bottom: 0;
        margin-right: 1.5rem;
    }
}

@media(min-width:$bp-l) {
    .lg-card__body {
        padding: 30px;
    }

    .lg-card__title {
        font-size:2.25rem;
    }

    .lg-card__sub-title {
        font-size:1.5rem;

        &:after {
            width: 60px;
        }
    }
}