@use '../../abstracts/' as *;

.product-list {
    @extend %listreset;
}

.product-list__item {
    border-radius: 10px;
    box-shadow: 0px 4px 24px 0px #0000000F;
    border: 1px solid $color-light-gray;
    margin-bottom: 32px;
    container-type: inline-size;
    overflow: hidden;
}

.product-list__top {
    background: $color-blue;
    color: $color-white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 4px 32px;
    @include x-rem(font-size, 12px);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.product-list__body {
    padding: 16px 32px;
    background:var(--color-white);
}

.product-list__info {
    margin-bottom: 16px; 
}

.product-list__tag {
    text-transform: uppercase;
    font-weight: $fontWeight-extra-bold;
}

.product-list__label {
    position: relative;
}

.product-list__title {
    @include x-rem(font-size, 26px);
    color: $color-blue;
    margin-bottom: 16px;
}

.product-list__links {
    display: flex;
    flex-direction: column;
}

.product-list__btn {
    width: 100%;
    margin-bottom: 16px;

    &:last-child {
        border: 2px solid $color-blue;
        background: $color-white;
        color: $color-blue;
    }
}

// product match 
.product-list__item--match {
    display: flex;
    flex-direction: column;

    .product-list__top {
        border-radius: 0;
    }

    .product-list__body {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .product-list__links {
        margin-top: auto;
    }
}

.product-list__seperator {
    display: none;
}

.product-list__heading {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
    padding: 32px;
    background: $color-dark-blue;

    .product-list__title {
        margin: 0;
        color: $color-white;
        position: relative;
        z-index: 1;
    }

    &.product-list__heading--media {
        background-size: cover;
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 6.38%, rgba(0, 0, 0, 0.6) 100%),
                    linear-gradient(0deg, rgba(2, 52, 85, 0.9), rgba(2, 52, 85, 0.9));
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }

        &.image-only {
            height: 230px;
            display: flex;
            flex-direction: column;

            .product-list__title {
                margin-top: auto;
            }

            &:after {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 72%, rgba(0, 0, 0, 0.2) 100%),
                        linear-gradient(179.78deg, rgba(0, 6, 10, 0) 55.78%, rgba(0, 6, 10, 0.4) 77.21%, rgba(0, 6, 10, 0.6) 99.81%);
            }
        }
    }
}

// container queries 
@container (width > 400px) {
    .product-list__separator {
        display: block;
        height: 13px;
        width: 1px;
        background: $color-white;
        margin: 0 8px;
    }

    .product-list__top--match {
        justify-content: center;
    }

    .product-list__links--match {
        flex-direction: row;
        margin: 0 -2%;

        .product-list__btn {
            margin: 0 1% 16px 1%;
        }
    }

    .product-list__heading {
        &.product-list__heading--media {
            &.image-only {
                height: 302px;
            }
        }
    }
}

@container (width > 768px) {
    .product-list__body {
        display: flex;

        &.product-list__body--match {
            display: block;
        } 
    }

    .product-list__info {
        padding-right: 32px;

        ul {
            column-count: 2;

            li {
                margin-right: 16px;
            }
        }
    }

    .product-list__links {
        margin-left: auto;
    }

    .product-list__btn {
        min-width: 200px;
    }
}

@media(min-width: $bp-m) {
    .product-list--match {
        display: flex;
        justify-content: center;
        margin: 0 -2%;

        .product-list__item {
            max-width: 500px;
            width: 48%;
            margin: 0 1% 32px 1%;
        }
    }
}