@forward './filters';
@use '../../abstracts/' as *;

.product-search {
    margin-bottom:50px;
}

.product-search__main {
    padding-inline:20px;
    background:var(--color-light-blue);

    &:has(.product-list__item) {
        padding-top:40px;
        padding-bottom:50px;
    }
}

.product-search__announcement {
    margin-bottom:30px;
    font-size:clamp(1.25rem, 1.25vw, 1.5rem);
    font-weight:$fontWeight-bold;
    color:var(--color-dark-blue);
}

@media(min-width:$bp-mw){
    .product-search__main:has(.product-list__item) {
        padding-top:120px;
    }

    .product-search__announcement {
        text-align: center;
    }
}