@use '../../abstracts/' as *;

.location-list {
    @extend %listreset;

    .location {
        padding:24px 0;
        border-top:1px solid $color-light-gray;

        &:first-child {
            padding-top:0;
            border-top:0;
        }
    }
}

.location.active {
    box-shadow: 0 6px 24px 0 rgba(2, 33, 53, .12);
}

.location__header {
    display: flex;
    justify-content: space-between;
    margin-bottom:24px;
}

.location__title {
    margin:0;
    font-size:clamp(1.25rem, 1.3vw, 1.625rem);
    font-weight:$fontWeight-extra-bold;
}

.location__distance {
    font-size:clamp(1rem, 1.04vw, 1.25rem);
    font-weight: $fontWeight-semibold;
}

.location__address-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:14px;
}

.location__group {
    display: flex;
    gap: 3px;
}

.location__address,
.location__dl {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: $fontWeight-normal;
}

.location__link {
    font-weight:normal;
    color:$color-base;
}

.location__dl {
    margin-top:14px;
    margin-bottom:0;
}

.location__dd {
    margin:0;
}

.location__actions {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top:24px;

    .location__types {
        order:-1;
    }
}

.location__types  {
    display: flex;
    align-items: center;
    gap:20px;

    .icon {
        width:20px;
        height:20px;
        fill: $color-primary;
    }
}

@media(min-width:$bp-m){
    .location__dl {
        margin:0;
    }

    .location__address-wrap {
        flex-direction: row;
        gap:0;
        justify-content: space-between;
    }

    .location__actions {
        align-items: center;
        flex-direction: row;
        gap: 0;
        justify-content: space-between;

        .location__types {
            order:2;
        }
    }

    .location-list {
        max-height:967px;
        padding-inline-start:0;
        margin-inline-end:7px;
        overflow-y: auto;

        /* Style for the entire scrollbar */
        &::-webkit-scrollbar {
            width: 14px; /* Adjust the width of the scrollbar */
        }

        /* Style for the scrollbar track */
        &::-webkit-scrollbar-track {
            margin-right:7px;
            background:none;
        }

        /* Style for the scrollbar thumb */
        &::-webkit-scrollbar-thumb {
            background: $color-primary; /* Background color of the thumb */
            border-radius: 20px; /* Make the thumb rounded */
        }

        /* Style for the scrollbar thumb on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $color-primary; /* Darker color on hover */
        }

        .location {
            padding-inline-end:46px;
        }
    }
}