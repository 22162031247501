@use '../../abstracts/' as *;

.interior-heading.interior-heading--alt {
    position: relative;
    z-index:1;
    background: var(--color-dark-blue);

    &:after {
        display: none;
    }
}

@media(min-width:$bp-mw){
    .interior-heading.interior-heading--alt {
        text-align: center;

        &:has(.interior-heading__media) {
            display: grid;
            grid-template-columns: 1fr 45%;
            text-align: left;

            &:after {
                @include pseudo();
                top:0;
                left:0;
                width:100%;
                height:100%;
                background: linear-gradient(to right,  rgba(0,49,82,1) 0%,rgba(0,49,82,0) 100%);
                z-index: -1;
            }

            .interior-heading__text {
                padding-bottom:clamp(35px, 3.6vw, 70px);
            }
        }

        .interior-heading__text {
            position:relative;
            padding-top:clamp(20px, 3.6vw, 70px);
            padding-bottom:clamp(60px, 6.3vw, 90px);
            padding-left:clamp(80px, 8.3vw, 160px);
            padding-right:25px;
        }
    
        .interior-heading__media {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: -1;
            overflow: hidden;
    
            .interior-heading__img {
                left: 0;
                min-width: 100%;
                top: -25%;
                height: auto;
                min-height: 100%;
            }
        }
    }
}

@media(min-width:120em){
    .interior-heading__text {
        padding-inline:clamp(80px, 8.3vw, 160px);
    }
}