@use '../../abstracts/' as *;

.radio-item label {
    position:relative;
    display: inline-flex;
    gap:8px;
    cursor: pointer;

    &:before {
        @include pseudo(block, static, '');
        width:18px;
        height: 18px;
        border:1px solid #757575;
        background:var(--color-white);
        border-radius:50%;
        flex-shrink:0;
    }

    &:after {
        @include pseudo(block, absolute, '');
        top:3px;
        left:3px;
        width:12px;
        height:12px;
        border-radius:50%;
        background:$color-primary;
        opacity:0;
        line-height: 1.4;
        transition:opacity .3s ease-in-out;
    }

    &:has(input:checked):after {
        opacity: 1;
    }
}

.radio-item__input {
    position:absolute;
    top:2px;
    left:0;
    z-index: -1;
    width:18px;
    height:18px;
}