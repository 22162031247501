@use '../../abstracts/' as *;

// Callouts Carousel 
.container--callouts-carousel {
    position: relative;

    .swiper-button-prev,
    .swiper-button-next {
        display: none;
        top: 50%;
        transform: translateY(-50%);
        background: none;

        &:after {
            color: $color-dark-blue;
            @include x-rem(font-size, 20px);
        }
    }

    .swiper-button-next {
        right: 1rem;
    }

    .swiper-button-prev {
        left: 1rem;
    }

    .swiper-pagination {
        margin-top: 2rem;
    }

    .swiper-pagination-bullet {
        border: 2px solid $color-dark-blue;
        width: 15px;
        height: 15px;
        opacity: 1;
        background: none;

        &.swiper-pagination-bullet-active {
            background: $color-dark-blue;
        }
    }
}

.callouts-carousel-list {
    .card {
        padding: 0;
        box-shadow: none;
        border-radius: 0;
        text-align: center;
        height: auto;
        background: none;

        &:after {
            display: none;
        }

        .card__content {
            padding: .5rem;
        }
    }
}

@media(min-width: $bp-m) {
    .container--callouts-carousel {
        padding: 0 4rem;

        .swiper-button-prev,
        .swiper-button-next {
            display: flex;
        }
    }
}