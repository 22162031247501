@forward 'radioButton';
@forward 'checkbox';
@forward 'login-form';

@use '../../abstracts' as *;

$inputHeight: 46px !default;
$inputBg: #ffffff !default;
$inputBorderColor: #97B7CB !default;
$inputBorderWidth: 1px !default;
$inputBorderRadius: 2px !default;
$inputBoxShadow: inset 0 1px 5px rgba(0, 0, 0, 0.22) !default;
$inputBoxShadowActive: inset 0 1px 5px rgba(0, 0, 0, 0.22), inset 0 0 0 2px $color-blue !default;


/* ================================================================
   Wraps
   ================================================================ */

.form-group {
    position:relative;
    margin-bottom: 1rem;
}

[data-sf-role="form-container"] {
    .form-group {
        position:relative;

        label {
            position:absolute;
            top:0;
            left:14px;
            font-family: var(--font-secondary);
            font-size:0.625rem;
            font-weight:$fontWeight-medium;
            color: #6B6B6B;
            opacity: 0;
            transition:opacity .2s ease-in-out;
        }

        &:focus-within,
        &:has(.form-control:not(:placeholder-shown)){
            label {
                opacity: 1;
            }
        }
    }
}


/* ================================================================
   Labels/Indicators
================================================================ */

label, legend {
    font-size:0.875rem;
    font-weight:$fontWeight-medium;
    color:var(--color-dark-gray);
    cursor: pointer;
}

.form-control-label {
    display: block;
    font-weight: bold;
}

.required-indicator {
    position: relative;
    padding-right: 10px;

    &:after {
        display: block;
        content: '*';
        position: absolute;
        right: 0;
        top: 0;
        color: #EB0000;
    }
}

.error-message {
    margin-top: 5px;
    font-size: 1em;
    color: #EB0000;
}



/* ================================================================
   Controls/Inputs
   ================================================================ */

%form-control {
    width: 100%;
	@include x-rem(height, $inputHeight);
	padding: 5px 14px;
	border: $inputBorderWidth solid $inputBorderColor;
	border-radius: $inputBorderRadius;
	box-shadow: none;
	background: $inputBg;
    font-family: var(--font-secondary);
    font-weight:$fontWeight-medium;
	color: $color-black;
	font-size: 1rem;
	will-change: border-color;
	transition: border-color $transition-speed ease-in-out, box-shadow $transition-speed ease-in-out;

    &::placeholder {
        color: #6B6B6B;
    }

	&:focus {
		box-shadow: 0 0 4px 1px transparentize($color-blue, .25);
	}

    @media(max-width:$bp-mw-max){
        -webkit-appearance: none;
    }
}

.form-control,
.form-select,
.form-textarea {
    @extend %form-control;
}

.form-textarea {
	padding: 1rem;
	min-height: 100px;
}


.radio-item,
.checkbox-item {
    position:relative;

    &:has(input:checked) label:after {
        opacity: 1;
    }
}


[data-sf-role="error-message"] {
    margin-top:1rem;
    color:#EB0000;

    &:empty {
        display: none;
    }
}

[data-sf-role="form-container"] .row {
    row-gap: 0;
}