@use '../../abstracts/' as *;

// Post List
.post-list-container,
.featured-list-container {
    container-type: inline-size;
}

.post-list,
.featured-list {
    padding: 0;
    margin: 0;
}

.post-list__item,
.featured-list__item {
    list-style: none;
}

@container (width >=450px) {
    .post-list,
    .featured-list {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 32px;
    }
}

@container (width >=920px) {
    .post-list {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 32px 40px;
    }
}

// Post Detail
.post-detail {
    max-width: 1200px;
    margin:0 auto;
    padding-bottom: 32px;
}

.post-detail__title {
    margin-bottom: 8px;
}

.post-detail__author {
    @include x-rem(font-size, 14px);
}

.post-detail__author-link {
    font-weight: $fontWeight-medium;
}

.post-detail__meta {
    margin-bottom: 16px;
    @include x-rem(font-size, 14px);
}

.post-detail__tag {
    text-transform: uppercase;
    letter-spacing: .16px;
}

.post-detail__img {
    margin-bottom: 16px;
}

.post-detail__video {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    margin-bottom: 16px;
}

.post-detail__iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.post-detail__content {
    font-family: $font-secondary;
}