@use '../../abstracts' as *;

.dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown__container {
    position: relative;
}

.dropdown__btn {
    border: 2px solid $color-blue;
    background: $color-blue;
    color: $color-white;
    font-weight: $fontWeight-bold;
    border-radius: 4px;
    padding: 10px 42px 10px 32px;
    cursor: pointer;
    position: relative;
    text-transform: uppercase;
    letter-spacing: .75px;

    &:after {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        border-right: 2px solid $color-white;
        border-bottom: 2px solid $color-white;
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%) rotate(45deg);
    }

    &.active {
        &:after {
            transform: translateY(-50%) rotate(-135deg);
        }
    }
}

.dropdown__list {
    @extend %listreset;
    display: none;
    position: absolute; 
    left: 0;
    z-index:10;
    width: 100%;
    background: $color-white;
    max-height: 200px;
    overflow-y: scroll;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.1);
}

.dropdown__link {
    display: block;
    padding: 4px 8px;
}

.dropdown__item {
    &:nth-child(even) {
        background: $color-off-white;
    }
}