@use '../../abstracts' as *;

.image-card {
    position: relative;
    border-radius: 8px;
    background: $color-white;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.1);
    padding: 0;
    height: auto;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;

    .image-card__title {
        transition:color .3s ease-in-out;
    }

    &:hover,
    &:focus-within {
        .image-card__title {
            color: $color-green;
        }
    }
}

.image-card__media {
    position: relative;
    height: 180px;
}

.image-card__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-card__icon {
    width: 60px;
    height: 60px;
    background: rgba(203, 96, 21, .86);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.image-card__icon-svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: $color-white;
}

.image-card__link {
    position: absolute;
    top: 0;
    left: 0;
    z-index:2;
    width: 100%;
    height: 100%;
}

.image-card__tag {
    background: $color-deep-orange;
    color: $color-white;
    padding: 8px 30px;
    @include x-rem(font-size, 13px);
    text-transform: uppercase;
    letter-spacing: .16px;
    display: flex;
    align-items: center;
}

.image-card__type {
    @include x-rem(font-size, 12px);
    text-transform: uppercase;
    letter-spacing: .16px;
    font-weight: $fontWeight-extra-bold;
}

.image-card__separator {
    display: inline-block;
    height: 13px;
    width: 1px;
    background: $color-white;
    margin: 0 8px;
}

.image-card__text {
    font-weight: $fontWeight-medium;
    margin: 8px 0;
}

.image-card__read-more {
    display: block;
    color: $color-blue;
    font-weight: $fontWeight-extra-bold;
    margin-top: auto;
}

.image-card__date {
    @include x-rem(font-size, 13px);
}

.image-card__body {
    --color-base:#{$color-dark-gray};
    padding: 20px 30px 30px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color:var(--color-base);
}

.image-card__meta {
    @include x-rem(font-size, 13px);
    color: $color-deep-orange;
}

.image-card__title {
    @extend %item__title;
    margin-bottom: 4px;
    font-size:clamp(1.125rem, 1.04vw, 1.25rem);
    color:$color-dark-blue;
}

.image-card__author {
    @include x-rem(font-size, 13px);
    color: $color-dark-blue;
}

@media(min-width: $bp-m) {
    .image-card__media {
        height: 260px;
    }
}

@media(min-width:$bp-l) {
    .image-card__icon {
        width: 86px;
        height: 86px;
    }

    .image-card__icon-svg {
        width: 2.5rem;
        height: 2.5rem;
    }
}
