@use '../abstracts' as *;

.site-footer {
    content-visibility: auto;
}

.socials {
    @extend %listreset;
    display: flex;
    align-items: center;
    gap:30px;

    svg {
        max-width:25px;
        max-height:31px;
        fill:var(--color-blue);
    }

    li:first-child svg {
        width:14px;
        height:31px;
    }

    li:nth-child(2) svg {
        width:19px;
        height:17px;
    }

    li:nth-child(3) svg {
        width:20px;
    }

    li:nth-child(4) svg {
        width:25px;
    }

    li:nth-child(5) svg {
        width:20px;
    }
}

.footer-top {
    display: grid;
    gap:60px;
    padding-block-start:clamp(50px, 3.6vw, 70px);
}

.footer-col.footer-col--logo {
    display: grid;
    gap:50px;
    width: max-content;
    justify-self:center;

    .sf-contentblock:nth-child(2) {
        justify-self: center;
    }
}

.footer-bottom-wrap {
    padding-block:40px;
    background:var(--color-off-white);
}

.footer-bottom {
    display: grid;
    gap:40px;
    font-family: var(--font-secondary);
    font-size:0.875rem;
}

.footer-col.footer-col--links {
    ul {
        @extend %listreset;
        column-count:2;
        column-gap: clamp(16px, 2.08vw, 40px);

        a {
            display: inline-block;
            padding-block:12px;
            font-family:var(--font-secondary);
            font-weight:$fontWeight-bold;
            text-decoration: none;

            &:hover, &:focus-visible {
                text-decoration: underline;
            }
        }
    }
}

.footer-col.footer-col--copyright {
    display: flex;
    flex-direction: column;
    gap:10px;
}

.footer-sublinks {
    margin-top:20px;
    
    ul {
        @extend %listreset;
        display: flex;
        flex-direction: column;
        gap:16px;
    
        a {
            font-size:0.875rem;
            font-weight:$fontWeight-normal;
            text-decoration: none;
            color:var(--color-blue);
    
            &:hover, &:focus-visible {
                text-decoration: underline;
            }
        }
    }
}

@media(min-width:$bp-m){
    .footer-top {
        grid-template-columns: auto auto;
        gap:clamp(25px, 2.6vw, 50px);
    }
    
    .footer-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .footer-col.footer-col--logo {
        justify-self: start;
    }

    .footer-sublinks {
        margin-top:0;

        ul {
            flex-direction: row;
            gap:10px;
    
            li + li {
                padding-left:10px;
                border-left:1px solid #CBCBCB;
            }
        }
    }
}

.footer-col.footer-col--form {
    .underline:after {
        width:30px;
        height:4px;
    }

    h2 {
        font-size:clamp(1rem,1.04vw,1.125rem);
    }

    legend {
        @extend %sronly;
    }
}

@media(min-width:$bp-l){
    .footer-top {
        grid-template-columns: max-content 1fr max-content;
    }

    .footer-bottom-wrap {
        padding-block:30px;
    }

    .footer-col.footer-col--links {
        ul {
            column-count:3;

            li:nth-child(4) {
                break-after: column;
            }
        }
    }

    .footer-col.footer-col--copyright {
        justify-self: start;
        flex-direction: row;
        flex-wrap: wrap;
        gap:16px;
        
        .footer-copyright {
            padding-left:10px;
            border-left:1px solid var(--color-dark-blue);
        }

        .footer-sublinks {
            width:100%;
        }
    }
}