@use '../../abstracts' as *;

.document {
	&:last-child {
		.document__link {
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
		}
	}
}

.document__link {
	display: flex;
	align-items: center;
	background: $color-blue;
	color: $color-white;
	padding: 16px 30px;
}

.document__icon {
	width: 20px;
	height: 20px;
	fill: var(--color-white);
	margin-right: 16px;
}

.document__title {
	font-weight: $fontWeight-semibold;
}
