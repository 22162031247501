@use '../../abstracts/' as *;

.nav-callout__img {
  width:100%;  
}

.nav-callout__content {
    --color-link:var(--color-white);
    padding:25px 30px 35px;
    background:var(--color-deep-orange);
    color:var(--color-white);

    .button {
        padding-block:12px;
        border-color:currentColor;
        color:currentColor;
    }
}

.nav-callout__title {
    font-size:2.25rem;
    font-weight:$fontWeight-extra-bold;
    line-height:1.2;
}

.nav-callout__text {
    margin-top:12px;
}

.nav-callout__action {
    margin-top:20px;
}

@media(min-width:$bp-l){
    .nav-callout__media {
        border-radius:20px 20px 0 0;
        overflow: hidden;
    }

    .nav-callout__content {
        border-radius: 0 0 20px 20px;
    }
}