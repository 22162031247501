@forward './filters';
@forward './listing';
@use '../../abstracts/' as *;

.locator {
    overflow: hidden;
}

.locator__map {
    padding-top:75%;   
    max-height:1188px;
}

.locator__main {
    padding-block:30px;
    padding-inline:20px;
}

.locator__results-num {
    margin-bottom:clamp(32px, 2.6vw, 50px);
    font-size:clamp(1.25rem, 1.25vw, 1.5rem);
}

.locator__legend {
    border-bottom:1px solid #D6D6D6;
    margin-bottom:32px;
    padding-bottom:24px;
}

.locator__legend-open,
.locator__legend-close {
    font-size:0.875rem;
    @extend %buttonreset;
    font-weight:$fontWeight-bold;
}

.locator__legend-open {
    display: flex;
    align-items: center;
    gap:10px;
    color:$color-dark-blue;
}

.locator__legend-close {
    color:$color-white;
}

.location-type-list {
    display:flex;
    flex-direction: column;
    gap:30px;
}

.location-type__header {
    display: flex;
    align-items: center;
    gap:5px;
}

.location-type__title {
    font-size:0.875rem;
}

@media(min-width:$bp-mw){
    .locator__main {
        padding-inline:0;
    }

    .locator__layout {
        display: grid;
        grid-template-columns: 31% 55%;
        justify-content: end;

        .locator__map {
            order:2;
            padding-top:0;
        }
    }

    .locator__main {
        padding-top:100px;
    }

    .locator__filters {
        position:relative;
        z-index: 1;
        margin-bottom:-77px;
    }
}

@media(min-width:$bp-l){
    .locator__map {
        height:1188px;
    }
}