/* ================================================================
   Variables
   ================================================================ */


/* Colors
================================================================ */

// Color Palette
$color-green: #00853f;
$color-blue: #005288;
$color-dark-blue:#003152;
$color-orange: #EB6200;
$color-burnt-orange: #AB5212;
$color-deep-orange: #BD5A14;
$color-bright-orange: #CB6015;
$color-vivid-orange: #FF8833;
$color-sky-blue: #629bc0;
$color-light-sky: #C7E9FF;
$color-off-white: #F8F8F8;
$color-light-blue: #E1EDF5;
$color-light-gray: #D6D6D6;
$color-dark-gray: #2E2E2E;
$color-charcoal-gray: #3D3D3D;
$color-white:#fff;
$color-black:#000;
$color-red: #BB2124;

// Color Variables
$body-bg: $color-white;
$color-base: var(--color-base);
$color-invert: $color-white;
$color-primary: var(--color-site-primary);
$color-secondary: var(--color-site-secondary);
$color-white:$color-white;

// Color Map
$colors: (
    "site-primary": $color-blue,
    "site-secondary": $color-green,
    "link": $color-blue,
    "heading":$color-dark-blue,
    "base": $color-dark-gray,
    "green": $color-green,
    "blue": $color-blue,
    "dark-blue": $color-dark-blue,
    "burnt-orange": $color-burnt-orange,
    "deep-orange": $color-deep-orange,
    "bright-orange": $color-bright-orange,
    "vivid-orange": $color-vivid-orange,
    "sky-blue": $color-sky-blue,
    "light-sky": $color-light-sky,
    "off-white": $color-off-white,
    "light-blue": $color-light-blue,
    "light-gray": $color-light-gray,
    "dark-gray": $color-dark-gray,
    "charcoal-gray": $color-charcoal-gray, 
    "white": $color-white,
    "black": $color-black
);


/* Typography
================================================================ */
$base-font-size:16px;
$font-primary: "Manrope", "Manrope-fallback", sans-serif;
$font-secondary: "Inter", "Inter-fallback", sans-serif;
$font-heading: $font-primary;

$fontWeight-light: 300;
$fontWeight-normal: 400;
$fontWeight-medium: 500;
$fontWeight-semibold: 600;
$fontWeight-bold: 700;
$fontWeight-extra-bold: 800;

$typography-margin-bottom: 25px;

/* Grid
================================================================ */
// Breakpoints
$bp-s: 20em; // 320px
$bp-s-max: 29.938em; // 479px
$bp-sw: 30em; // 480px
$bp-sw-max: 47.938em; // 767px
$bp-m: 48em; // 768px
$bp-m-max: 59.938em; // 959px
$bp-mw: 60em; // 960px
$bp-mw-max: 71.188em; // 1139px
$bp-l: 72em; // 1152px
$bp-l-max: 81.188em; // 1299px
$bp-xl: 81.25em; // 1300px
$bp-xxl: 87.5em; // 1400px

$breakpoints: (
    xs: 0, 
    sm: $bp-s, 
    md: $bp-m,   
    lg: $bp-l, 
    xl: $bp-xl,
    xxxl: $bp-xxl
);

$breakpointsSimple: (
  xs: 0,
  lg: $bp-l,
);

$grid-container-width:87.5em;
$grid-gutter-em: 1.5625rem;

/* Misc
================================================================ */
$transition-speed: 0.4s;