@use '../../abstracts' as *;

.card {
    position:relative;
    border-radius:8px;
    padding:1.875rem 1.875rem 3rem 1.875rem;
    background:$color-white;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.1);

    &:after {
        @include pseudo();
        bottom:0;
        left:0;
        width:100%;
        height:10px;
        background:$color-secondary;
        border-radius: 0px 0px 10px 10px;
    }

    & > .card__link {
        position:absolute;
        z-index:1;
        inset:0;
        border-radius:8px;
    }

    .tns-item & {
        position:relative;
        height:100%;
    }
}

.card__header {
    display: flex;
    gap:1.25rem;
    align-items: center;
    flex-direction: column;
}

.card__title {
    --color-heading:#{$color-dark-blue};
    @extend %item__title;
}

.card__date {
    @include x-rem(font-size, 18px);
}

.card--slide {
    padding:0;
    height:auto;

    a {
        font-weight: $fontWeight-medium;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    &:after {
        display: none;
    }

    .card__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .card__tag {
        background: $color-deep-orange;
        color: $color-white;
        padding: .5rem 1.875rem;
        font-size:0.8125rem;
        text-transform: uppercase;
        letter-spacing: .16px;
    }

    .card__type {
        @include x-rem(font-size, 12px);
        text-transform: uppercase;
        letter-spacing: .16px;
        font-weight: $fontWeight-extra-bold;
    }

    .card__media {
        position: relative;
        height: clamp(180px, 13vw, 260px);
    }

    .card__body {
        padding: 1.25rem 1.875rem 1.875rem;
    }

    .card__meta {
        font-size:0.8125rem;
        color: $color-deep-orange;
    }

    .card__title {
        font-size:clamp(1.125rem, 1.04vw, 1.25rem);
    }

    &:hover,
    &:focus-within {
        .card__title {
            color: $color-green;
        }
    }
}

// media queries
@media(min-width:$bp-m){
    .card__header {
        flex-direction: row;
        flex-wrap: nowrap;
    }
}