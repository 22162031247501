@use '../../abstracts/' as *;

// advisor detail 
.advisor-detail {
    container-type: inline-size;
}

.advisor-detail__back {
    display: inline-block;
    margin-bottom: 32px; 
}

.advisor-detail__heading {
    margin-bottom: 20px;
}

.advisor-detail__name,
.advisor-detail__title {
    @include x-rem(font-size, 26px);
    font-weight: $fontWeight-medium;
}

.advisor-detail__name {
    margin: 0;
}

.advisor-detail__img {
    display: block;
    margin-bottom: 16px;
}

.advisor-detail__type {
    margin-bottom: 16px;
    @include x-rem(font-size, 16px);
    font-weight: $fontWeight-extra-bold;
}

.advisor-detail__address {
    margin: 16px 0;
    font-style: normal;
}

.advisor-detail__link {
    display: block;
}

.advisor-detail__cta {
    display: inline-block;
    border: 2px solid $color-green;
    background: $color-green;
    color: $color-white;
    border-radius: 4px;
    padding: 6px 16px;
    font-weight: $fontWeight-medium;
    margin: 8px 8px 8px 0;
    transition: .3s ease-in-out;
    margin: 16px 0;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $color-white;
        background: $color-blue;
        border-color: $color-blue;
    }
}

.advisor-detail__flex {
    margin-bottom: 32px;
}

.advisor-detail__bio,
.advisor-detail__footnote {
    padding: 16px 0;
    border-top: 1px solid $color-light-gray;
}

.advisor-detail__footnote {
    @include x-rem(font-size, 14px);
}

@media(min-width: $bp-m) {
    .advisor-detail__flex {
        display: flex;
    }

    .advisor-detail__media {
        margin-right: 32px;
    }

    .advisor-detail__type {
        @include x-rem(font-size, 20px);
    }
}

@media(min-width: $bp-l) {
    .advisor-detail__heading {
        display: flex;
    }

    .advisor-detail__name,
    .advisor-detail__title {
        @include x-rem(font-size, 32px);
    }

    .advisor-detail__name {
        margin-right: 32px;
    }

    .advisor-detail__title {
        position: relative;

        &:before {
            content: '';
            display: block;
            height: 32px;
            width: 2px;
            background: $color-dark-blue;
            position: absolute;
            left: -16px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

// mortgage banker detail 
.mb-detail {
    container-type: inline-size;
}

.mb-detail__back {
    display: inline-block;
    margin-bottom: 32px;
}

.mb-detail__heading {
    margin-bottom: 20px;
}

.mb__name,
.mb-detail__title {
    @include x-rem(font-size, 26px);
    font-weight: $fontWeight-medium;
}

.mb-detail__name {
    margin: 0;
}

.mb-detail__img {
    display: block;
    margin-bottom: 16px;
}

.mb-detail__label {
    font-weight: $fontWeight-bold;
}

.mb-detail__address {
    margin: 16px 0;
    font-style: normal;
}

.mb-detail__nmls {
    margin-bottom: 16px;
}

.mb-detail__cta {
    display: inline-block;
    border: 2px solid $color-green;
    background: $color-green;
    color: $color-white;
    border-radius: 4px;
    padding: 6px 16px;
    font-weight: $fontWeight-medium;
    margin: 8px 8px 8px 0;
    transition: .3s ease-in-out;
    margin: 16px 0;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $color-white;
        background: $color-blue;
        border-color: $color-blue;
    }
}

.mb-detail__flex {
    margin-bottom: 32px;
}

.mb-detail__bio {
    padding: 16px 0;
    border-top: 1px solid $color-light-gray;
}

@media(min-width: $bp-m) {
    .mb-detail__flex {
        display: flex;
    }

    .mb-detail__media {
        margin-right: 32px;
    }
}

@media(min-width: $bp-l) {
    .mb-detail__heading {
        display: flex;
    }

    .mb-detail__name,
    .mb-detail__title {
        @include x-rem(font-size, 32px);
    }

    .mb-detail__name {
        margin-right: 32px;
    }

    .mb-detail__title {
        position: relative;

        &:before {
            content: '';
            display: block;
            height: 32px;
            width: 2px;
            background: $color-dark-blue;
            position: absolute;
            left: -16px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

@container(width <= 700px) {
    .mb-detail__heading {
        display: block;
    }

    .mb-detail__title:before {
        display: none;
    }
}

// author post 
.author-post {
    container-type: inline-size;
}

.author-post__heading {
    margin-top: 40px;
    padding-top: 32px;
    border-top: 1px solid $color-light-gray;
}

.author-post__name {
    margin: 0;
}

.author-post__title {
    display: block;
}

.author-post__img {
    margin: 0 0 16px;
}

.author-post__address {
    font-style: normal;
    margin: 16px 0;
}

.author-post__link {
    display: block;
    margin-bottom: 16px;
}

.author-post__blog-list {
    @extend %listreset;
}

.author-post__blog-item {
    border-radius: 8px;
    background: $color-white;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 32px;
}

.author-post__blog-url {
    text-decoration: none;
    font-weight: $fontWeight-medium;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.author-post__blog-media {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: relative;
    height: 180px;
}

.author-post__blog-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.author-post__blog-content {
    padding: 16px;
}

.author-post__blog-tag {
    @include x-rem(font-size, 14px);
    color: $color-base;
}

.author-post__blog-title {
    color: $color-blue;
    margin-bottom: 4px;
}

.author-post__blog-meta {
    @include x-rem(font-size, 14px);
    text-transform: uppercase;
}

.author-post__flex {
    margin-top:16px;
}

@container(width > 768px) {
    .author-post__flex {
        display: flex;
    }

    .author-post__left-col {
        margin-right: 40px;
        flex-shrink: 0;
    }

    .author-post__blog-list {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 16px;
    }

    .author-post__blog-media {
        height: 260px;
    }
}

@container(width > 1080px) {
    .author-post__blog-list {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}