@use '../abstracts/' as *;

.tab-nav-wrap {
    padding-inline:$grid-gutter-em;
}

.tab-nav__menu {
    @extend %listreset;
    display: flex;
    flex-wrap:wrap;
    align-items: center;
    justify-content: center;
    gap:20px;
    border-bottom:1px solid #D6D6D6;
    padding-bottom:32px;
    .tab-nav__item + .tab-nav__item {
        padding-left:20px;
        border-left:1px solid #D6D6D6;
        line-height:1;
    }
}

.tab-nav__link {
    position:relative;
    display: inline-block;
    font-size: 1.125rem;
    font-weight:$fontWeight-extra-bold;

    &:after {
        @include pseudo();
        bottom:-32px;
        left:0;
        width:100%;
        height:4px;
        background:$color-secondary;
        opacity: 0;
    }

    &.active {
        color:$color-secondary;

        &:after {
            opacity: 1;
        }
    }
}