@use '../../abstracts/' as *;

.hero-wrap {
    background: radial-gradient(110.72% 108.34% at -3.92% -8.98%, #006EB8 0%, #005288 31.39%, #003152 94.9%);

    & > .sf-col {
        display: flex;
        flex-direction: column;
        gap:40px;
        padding:20px 20px 60px;
    }
}

.hero {
    position: relative;
    z-index:1;

    .button {
        background:var(--color-white);
        color:var(--color-blue);
    }

    .button.button--outlined {
        border-color:var(--color-white);
        background:transparent;
        color:var(--color-white);
    }
}

.hero__text {
    container-type: inline-size;
}


.hero__actions {
    display: flex;
    flex-direction: column;
    gap:20px;
}

@container (width >= 500px) {
    .hero__actions {
        flex-direction: row;
        flex-wrap: wrap;
        gap:16px;
    }
}

@media(min-width:$bp-l){
    .hero-wrap {
        background: none;

        & > .sf-col {
            display: grid;
            padding:0;
            gap:0;
        }
    
        .hero,
        .login-form {
            grid-area:1/1;
        }
    
        .login-form {
            position: relative;
            z-index:2;
            max-width: 275px;
            justify-self: end;
            align-self:center;
        }
    }

    .hero {
        display: grid;
        grid-template-columns: 1fr 56.2%;
        padding:0;
        background: radial-gradient(110.72% 108.34% at -3.92% -8.98%, #006EB8 0%, #005288 31.39%, #003152 94.9%);

        &:after {
            @include pseudo();
            top:0;
            left:0;
            width:100%;
            height:100%;
            background:url('/dist/images/texture.png') no-repeat center center;
            background-size:cover;
            z-index: -1;
        }
    }

    .hero__text {
        padding-top:clamp(20px, 3.6vw, 70px);
        padding-bottom:clamp(60px, 6.3vw, 122px);
        padding-left:clamp(80px, 8.3vw, 160px);
        padding-right:25px;
    }

    .hero__media {
        position: relative;
        display: block;
        overflow: hidden;

        .hero__img {
            position: absolute;
            height: 100%;
            max-width: none;
            left:0;
            object-fit: cover;
        }
    }
}

@media(min-width:87.5em){
    .hero-wrap > .sf-col .login-form {
        right:40px;
    }
}

@media(min-width:105em){
    .hero-wrap > .sf-col .login-form {
        right:clamp(40px, 13vw, 250px);
    }
}

@media(min-width:120em){
    .hero__text {
        padding-inline:clamp(80px, 8.3vw, 160px);
    }

    .hero__media {
        overflow: visible;

        .hero__img {
            right: 0;
            left:auto;
        }
    }
}