@use '../abstracts' as *;

.sf-section {
    background-color:var(--sf-backgrоund-color);
    background-image:var(--sf-backgrоund-image);
    background-size:var(--sf-background-size);
    background-position:var(--sf-background-position);

    &[style*="--sf-backgrоund-color: #47799F"] {
        color:$color-white;
    
        a {
            color:currentColor;
        }
    }

    &.textured {
        position: relative;
        z-index:1;

        &:after {
            @include pseudo();
            top: 0;
            left: 0;
            z-index:-1;
            width: 100%;
            height: 100%;
            background: url('/dist/images/texture.png') no-repeat center center;
            background-size: cover;
        }
    }

    &.dark-blue-watermark {
        --color-base: #{$color-white};
        --color-link: #{$color-white};
        --color-heading: #{$color-white};

        background: linear-gradient(180deg, #323F48 0%, #343F46 47.76%, #2E373E 96.09%),
                linear-gradient(180deg, #2F404C 0%, #2F404C 47.76%, #293842 96.09%),
                linear-gradient(0deg, #2F404C, #2F404C);
        color:$color-base;
    }

    &.navy-white {
        .navy-white__header {
            background: $color-dark-blue;
            padding: 1rem;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom: 4px solid $color-green;
            container-type: inline-size;

            * {
                color: $color-white;
                margin: 0;
            }
        }

        .navy-white__body {
            margin: 0;
            padding: 1.5rem 0;
            background: $color-white;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            box-shadow: 0px 4px 16px 0px #0221350F;
        }
    }

    @container (width > 420px) {
        .navy-white__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &.blue-outline {
        border: 4px solid #00528833;
        border-radius: 8px;
    }

    &.white-layout {
        background: $color-white;
        border-radius: 8px;
        box-shadow: 0px 6px 24px 0px #0221350F;
        position: relative;
    }

    .sf-col {
        position: relative;
    }

    &.bg-img {
        .sf-section {
            background-image: none;
        }
    }
}

.sf-section--video {
    position:relative;
    z-index:1;
}

.sf-section--lazy-bg {
    background-image: none !important;

    &.inView {
        background-image:var(--sf-backgrоund-image) !important;
    }
}

.sc-video__wrapper {
    position: absolute;
    z-index:-1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding:0 1.875rem;
    overflow: hidden;
}

.sc-video__toggle {
    @extend %buttonreset;
    position:absolute;
    bottom:1rem;
    left:1rem;
    width: 25px;
    height: 25px;
    margin: 0 auto;
    z-index:1;

    .sc-video__pause-icon {
        display: none;
    }

    &.active {
        .sc-video__pause-icon {
            display: block;
        }

        .sc-video__play-icon {
            display: none;
        }
    }  
}

.sc-video__element {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
}

.-sc-video {
    position: relative;
}

.sf-col {
    background-color:var(--sf-background-color);
}

@media(min-width:$bp-l) {
    .sf-section {
        &.white-layout {
            &.mt {
                margin-top: -30px;
                z-index: 1;
            }
        }
    }
}