@use '../../abstracts/' as *;

.alert {
    position: relative;
    background: $color-deep-orange;
    color: $color-white;
    padding: 1rem 0;

    .container {
        display: flex;
        gap: 10px;
        flex-direction:  column;
    }
}

.alert__image {
    flex-shrink:  0;
}

.alert__content {
    display: flex;
    gap: 10px;
    align-items: center;

    p {
        margin-bottom: 4px;
    }

    a {
        color: $color-white;
        text-decoration: underline;
        font-weight: $fontWeight-bold;
    }
}

.alert__close {
    @extend %buttonreset;
    position: absolute;
    top: 1rem;
    right: .8rem;
    font-weight: $fontWeight-bold;
    color: $color-white;
}

.alert__button {
    align-self: center;
}

.alert--red {
    background: $color-red;
}

.alert--blue {
    background: $color-primary;
}

@media(min-width: $bp-l) {
    .alert {
        font-size: 1.125rem;

        .container {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .alert__button {
        margin-left: auto;
    }

    .alert__close {
        position: static;
        border-left: 1px solid $color-white;
        padding-left: 10px;
    }
}