@use '../../abstracts/' as *;

.locator__filters {
    display: flex;
    flex-direction: column;
    gap:10px;
    max-width:1352px;
    margin:0 20px 40px;
    border-radius: 8px;
    padding-block:40px;
    padding-inline:clamp(20px, 2vw, 40px);
    box-shadow: 0px 6px 24px 0px rgba(2, 33, 53, 0.06);
    background:var(--color-white);
}

.locator__filters-title {
    margin:0;
    font-size:clamp(1.625rem, 2.5vw, 2rem);
    white-space: nowrap;
}

.locator__submit,
.locator__geo {
    white-space: nowrap;
}

.locator__submit {
    margin-top:14px;
}

.locator__geo  {
    display: inline-flex;
    gap:8px;
    align-self: center;
    align-items: center;
    margin-top:20px;
    font-weight:$fontWeight-extra-bold;

    .icon {
        width:20px;
        height:20px;
    }
}

@media(min-width:$bp-mw){
    .locator__filters {
        flex-direction: row;
        align-items: flex-end;
        gap:24px;
        margin-inline:auto;
        box-shadow: 0px 6px 24px 0px rgba(2, 33, 53, 0.06);

        .locator__filter {
            max-width:220px;
        }
    }

    .locator__submit,
    .locator__geo {
        margin-top:0;
    }

    .locator__geo {
        align-self: flex-end;
        margin-bottom:10px;
    }
}