@use '../../abstracts/' as *;

.staff-list,
.banker-list,
.board-list {
    margin: 0;
    padding: 0;
    font-weight: $fontWeight-medium;
}

.staff-list__item,
.banker-list__item,
.board-list__item {
    list-style: none;
    margin-bottom: 32px;
    border-bottom: 1px solid $color-light-gray;
}

.staff-list__name {
    color: $color-blue;
    margin-bottom: 8px;
}

.staff-list__title {
    margin-bottom: 16px;
    font-style: italic;
    font-weight: $fontWeight-semibold;
}

.staff-list__img {
    margin-bottom: 16px;
}

.staff-list__icon,
.banker-list__icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;

    &.linkedin,
    &.email {
        width: 20px;
        height: 20px;
    }
}

.staff-list__link {
    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.staff-list__info {
    margin-bottom: 4px;
}

.staff-list__address {
    font-style: normal;
}

.staff-list__phone,
.staff-list__cell {
    display: flex;
    align-items: center;
}

.staff-list__col,
.banker-list__col {
    margin-bottom: 16px;
}

@media(min-width: $bp-mw) {
    .staff-list__item {
        display: flex;
    }

    .staff-list__col--left {
        width: 25%;
        padding-right: 32px;
    }

    .staff-list__col--right {
        width: 75%;
    }
}

// Banker List
.banker-list__item {
    border-bottom: 1px solid $color-light-gray;
}

.banker-list__name {
    color: $color-green;
    margin-bottom: 8px;
}

.banker-list__title {
    @include x-rem(font-size, 18px);
    margin-bottom: 16px;
}

.banker-list__address {
    font-style: normal;
}

.banker-list__cta {
    display: inline-block;
    border: 2px solid $color-blue;
    border-radius: 4px;
    padding: 6px 16px;
    font-weight: $fontWeight-medium;
    margin: 8px 8px 8px 0;
    transition: .3s ease-in-out;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $color-white;
        background: $color-blue;
    }
}

.banker-list__bio {
    padding-bottom: 32px;
}

@media(min-width: $bp-mw) {
    .banker-list__container {
        display: flex;
        justify-content: space-between;
    }
}

// Board List
.board-list {
    max-width: 900px;
}

.board-list__item {
    padding-bottom: 16px;
}

.board-list__img {
    margin-bottom: 16px;
}

.board-list__heading {
    margin-bottom: 16px;
}

.board-list__name,
.board-list__title {
    font-weight: $fontWeight-medium;
    color: $color-base;
    margin: 0;
}

.board-list__accordion {
    margin-bottom: 4px;
}

.board-list__accordion-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: $color-blue;
    font-weight: $fontWeight-semibold;
    position: relative;
    padding-left: 16px;
    transition: .3s ease-in-out;

    &:before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border-left: 1px solid $color-blue;
        border-bottom: 1px solid $color-blue;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%) rotate(-45deg);
    }

    &.active {
        &:before {
            transform: translateY(-50%) rotate(135deg);
        }
    }

    &:hover {
        color: $color-sky-blue;
    }
}

.board-list__accordion-panel {
    display: none;
    padding: 8px 16px;

    &.active {
        display: block;
    }
}

@media(min-width: $bp-mw) {
    .board-list__heading {
        display: flex;
        align-items: center;
    }

    .board-list__title {
        margin-left: 16px;
        padding-left: 16px;
        position: relative;

        &:before {
            content: '';
            display: block;
            height: 60%;
            width: 2px;
            background: $color-base;
            position: absolute;
            top: 20%;
            left: 0;
        }
    }
}

// advisor list
.advisor-list {
    @extend %listreset;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 4px;
    max-width: 920px;
    margin: 0 auto;
}

.advisor-list__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 32px;
}

.advisor-list__name {
    @include x-rem(font-size, 18px);
    margin: 0;
    font-weight: $fontWeight-extra-bold;
    color: $color-base;
}

.advisor-list__img {
    margin-bottom: 16px;
}

.advisor-list__tag {
    @include x-rem(font-size, 14px);
    display: inline-block;
    margin-left: 8px;
    position: relative;
    
    &:before {
        content: '';
        display: block;
        height: 13px;
        width: 1px;
        background: $color-base;
        position: absolute;
        left: -6px;
        top: 50%;
        transform: translateY(-50%);
    }

    &:first-child {
        margin: 0;

        &:before {
            display: none;
        }
    }
}

.advisor-list__cta {
    display: inline-block;
    border: 2px solid $color-blue;
    border-radius: 4px;
    padding: 6px 16px;
    font-weight: $fontWeight-medium;
    margin: 8px 8px 8px 0;
    transition: .3s ease-in-out;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $color-white;
        background: $color-blue;
    }
}

@media(min-width: $bp-m) {
    .advisor-list {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

// mortgage banker list
.mb-list {
    @extend %listreset;
    max-width: 900px;
    margin: 0 auto;
}

.mb-list__item {
    padding-bottom: 16px;
    margin-bottom: 32px;
    border-bottom: 1px solid $color-light-gray;
}

.mb-list__content {
    padding-right: 32px;
}

.mb-list__media {
    flex-shrink: 0;
}

.mb-list__name {
    @include x-rem(font-size, 22px);
    color: $color-dark-blue;
}

.mb-list__title {
    font-weight: $fontWeight-bold;
    margin-bottom: 16px;
}

.mb-list__label {
    font-weight: $fontWeight-bold;
}

.mb-list__nmls {
    margin-bottom: 16px;
}

.mb-list__app-url {
    margin-bottom: 16px;

    img {
        margin-right: 8px;
    }
}

.mb-list__address {
    font-style: normal;
    margin-bottom: 16px;
}

.mb-list__links {
    margin-bottom: 16px;
}

.mb-list__cta {
    display: inline-block;
    border: 2px solid $color-blue;
    border-radius: 4px;
    padding: 6px 16px;
    font-weight: $fontWeight-medium;
    margin: 8px 4px 8px 0;
    transition: .3s ease-in-out;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $color-white;
        background: $color-blue;
    }

    &.app-url {
        border-color: $color-green;
        background: $color-green;
        color: $color-white;

        &:hover,
        &:focus {
            border-color: $color-blue;
            background: $color-blue;
        }
    }
}

.mb-list__bio {
    margin: 16px 0;
}

@media(min-width: $bp-m) {
    .mb-list__flex {
        display: flex;
        justify-content: space-between;
    }
}

// author list 
.author-list-container {
    display: none;
}

.author-list {
    @extend %listreset;
}

.author-list__cta {
    display: block;
    padding: 8px 0;
    border-bottom: 1px solid $color-light-gray;
    transition: .3s ease-in-out;
    font-weight: $fontWeight-medium;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $color-sky-blue;
    }
}

.author-list__item {
    &:last-child {
        .author-list__cta {
            border: none;
        }
    }
}

@media(min-width: $bp-m) {
    .author-list-container {
        display: block;
    }
}