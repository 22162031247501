@use '../../abstracts/' as *;

.disclaimer {
    display: none;
    position: fixed;
    z-index: 9000;
    background: rgba(#000, .7);
    top: 0; bottom: 0; left: 0; right: 0;
  
    .proceed + .cancel { margin-left:16px; }
  
    .sfPageEditor & { display: none }
  }
  
  .disclaimer-modal {
    display: none;
  
    &.active { display: block; }
  }
  
  .disclaimer__modal {
    position: relative;
    width: 90%;
    top: 50%;
    left: 50%;
    background: $color-white;
    transform: translate(-50%, -50%);
    padding: 32px;
    font-family: $font-primary;
    font-size: 1rem;
  }
  
  .disclaimer__content { margin-bottom:16px; p{margin: 0 0 16px 0;} }
  
  .disclaimer__title {margin-bottom: 8px;}
  
  .disclaimer__logos {
  margin-top: 32px;
  .disclaimer__logo {
    max-width: 250px;
  }
  }
  
  .disclaimer__remove {
    position: absolute;
    background: transparent;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    top: 0;
    right: 0;
    padding: 6px;
    border: 0;
    cursor: pointer;
  
    &:after { content: '\2573'; color: #646464; }
    
    &:hover,&:focus {
        outline: 1px solid #007bff;
    }
  }
  
  @media(min-width: $bp-l) {
  .disclaimer__modal {
    width: 60%;
  }
  }