@use '../abstracts' as *;

/* Maps & Variables
================================================================ */
$textAlignments:left, right, center,justify;

$fontWeights: (
    "light":$fontWeight-light,
    "normal": $fontWeight-normal,
    "medium": $fontWeight-medium,
    "semibold": $fontWeight-semibold,
    "bold": $fontWeight-bold,
    "extra-bold": $fontWeight-extra-bold
);

$fonts: (
    "primary":$font-primary,
    "secondary":$font-secondary,
);

$textCase:(
    "upper":uppercase,
    "lower":lowercase,
    "cap":capitalized
);

$displays:(
    "none": none,
    "block":block,
    "inline-block":inline-block,
    "inline":inline,
    "flex":flex,
    "inline-flex":inline-flex
);

$flexDirections:(
    "row":row,
    "row-reverse":row-reverse,
    "column":column,
    "column-reverse":column-reverse
);

$sides: (
    all:"",
    vertical:"y",
    horizontal:"x",
    top:"t", 
    right:"r", 
    bottom:"b", 
    left:"l"
);

$spacer: 16px;
$numOfSpacers:15;

$widths: (
    "auto":auto,
    "sm": 25%,
    "md": 50%,
    "lg": 75%,
    "full": 100%
);

$grids: (
    "2x2": 2,
    "3x3": 3
);


$sectionPaddings: "small" 20 20, "medium" 40 60,
  "large" 40 80;

/* Mixins & Functions
================================================================ */
@mixin generate-text-alignment-classes($bpAbbv: '') {
    @each $alignment in $textAlignments {
        .text#{$bpAbbv}-#{$alignment} {
            text-align: $alignment;
        }
    }
}

@mixin generate-grid-classes($bpAbbv: ''){
    @each $grid, $value in $grids {
        .grid-inline#{$bpAbbv}-#{$grid} {
            display: inline-grid;
            grid-template-columns: repeat($value, minmax(0px, 1fr));
        }
        .grid#{$bpAbbv}-#{$grid} {
            grid-template-columns: repeat($value, minmax(0px, 1fr));
        }
    
        .grid#{$bpAbbv}-#{$grid}-auto {
            grid-template-columns: repeat($value, minmax(auto, auto));
        }

        .grid-inline#{$bpAbbv}-#{$grid}-auto {
            display: inline-grid;
            grid-template-columns: repeat($value, minmax(auto, auto));
        }
    }
}

@mixin generate-display-classes($bpAbbv: '') {
    @each $display, $displayVal in $displays {
        .d#{$bpAbbv}-#{$display} {
            display: $displayVal;
        }
    }
}

@function spacer-value($multiplier) {
    @return $spacer * $multiplier;
}

@mixin generate-spacing-classes($bpAbbv: ''){
    @each $side, $sideValue in $sides {
        @for $i from 0 through $numOfSpacers {
            $spaceValue: spacer-value($i * 0.25);
        
            @if ($sideValue == "") {
                .p#{$sideValue}-#{$bpAbbv}#{$i} {
                    padding: $spaceValue;
                }
                .m#{$sideValue}-#{$bpAbbv}#{$i} {
                    margin: $spaceValue;
                }
            } @else if ($sideValue == "y") {
                .p#{$sideValue}-#{$bpAbbv}#{$i} {
                    padding-top: $spaceValue;
                    padding-bottom: $spaceValue;
                }
                .m#{$sideValue}-#{$bpAbbv}#{$i} {
                    margin-top: $spaceValue;
                    margin-bottom: $spaceValue;
                }
            } @else if ($sideValue == "x") {
                .p#{$sideValue}-#{$bpAbbv}#{$i} {
                    padding-left: $spaceValue;
                    padding-right: $spaceValue;
                }
                .m#{$sideValue}-#{$bpAbbv}#{$i} {
                    margin-left: $spaceValue;
                    margin-right: $spaceValue;
                }
            } @else {
                .p#{$sideValue}-#{$bpAbbv}#{$i} {
                    padding-#{$side}: $spaceValue;
                }
                .m#{$sideValue}-#{$bpAbbv}#{$i} {
                    margin-#{$side}: $spaceValue;
                }
            }

            .gap-#{$bpAbbv}#{$i} {
                gap: $spaceValue;
            }

            .row-gap-#{$bpAbbv}#{$i} {
                row-gap: $spaceValue;
            }

            .col-gap-#{$bpAbbv}#{$i} {
                column-gap: $spaceValue;
            }
        }
    }
}

// Padding & Margin Classes
// Applied on sections when selecting padding / margin options
@each $name, $mobileSize, $desktopSize in $sectionPaddings {
    $scalingSize:calc(calc($desktopSize / 1920) * 100);

    .padding-top-#{$name} {
        padding-top:clamp(#{$mobileSize}px, #{$scalingSize}vw, #{$desktopSize}px);
    }
    .margin-top-#{$name} {
        margin-top:clamp(#{$mobileSize}px, #{$scalingSize}vw, #{$desktopSize}px);
        
    }
    .padding-bottom-#{$name} {
        padding-bottom:clamp(#{$mobileSize}px, #{$scalingSize}vw, #{$desktopSize}px);
        
    }
    .margin-bottom-#{$name} {
        margin-bottom:clamp(#{$mobileSize}px, #{$scalingSize}vw, #{$desktopSize}px);
    }
    .padding-left-#{$name} {
        padding-left:clamp(#{$mobileSize}px, #{$scalingSize}vw, #{$desktopSize}px);
    }
    .margin-left-#{$name} {
        margin-right:clamp(#{$mobileSize}px, #{$scalingSize}vw, #{$desktopSize}px);
    }
    .padding-right-#{$name} {
        padding-right:clamp(#{$mobileSize}px, #{$scalingSize}vw, #{$desktopSize}px);
    }
    .margin-right-#{$name} {
        margin-right:clamp(#{$mobileSize}px, #{$scalingSize}vw, #{$desktopSize}px);
    }
}

/* Text Helpers
================================================================ */
@include generate-text-alignment-classes();

@each $fontWeight, $weightValue in $fontWeights {
    // .text-normal { font-weight:normal; }
    .text-#{$fontWeight} {
        font-weight:$weightValue;
    }
}

@each $case, $caseValue in $textCase {
    // .text-upper { text-transform:uppercase; }
    .text-#{$case} {
        text-transform:$caseValue;
    }
}

@each $color, $colorValue in $colors {
    // .color-primary { color:$color-primary }
    .color-#{$color} {
        color:$colorValue;
    }
}

@each $color, $colorValue in $colors {
    // .bg-primary { background-color:$color-primary }
    .bg-#{$color} {
        background-color:$colorValue;
    }
}

@each $font, $fontValue in $fonts {
    // .font-primary { font-family:$font-primary }
    .font-#{$font} {
        font-family:$fontValue;
    }
}

@each $width, $widthValue in $widths {
    // .w-sm { width:25% }
    .w-#{$width} {
        width:$widthValue;
    }
}

/* Layout Helpers
================================================================ */
[class*="grid-"] {
    display: grid;
    gap:calc($grid-gutter-em * 2);

    &:is(ul) {
        @extend %listreset;
    }
}

@include generate-grid-classes();

/* Element Helpers
================================================================ */
.clearfix {
    @extend %clearfix;
}

.list-unstyled {
    @extend %listreset;
}

.flex-wrap {
    flex-wrap: wrap;
}

.rounded {
    border-radius: 20px;
}

@include generate-spacing-classes();
@include generate-display-classes();

/* Media Queries
================================================================ */
@each $bpAbbv, $bp in $breakpoints {
    @media(min-width:$bp){
        @include generate-grid-classes('-#{$bpAbbv}');
        @include generate-display-classes('-#{$bpAbbv}');
        @include generate-text-alignment-classes('-#{$bpAbbv}');
        @include generate-spacing-classes('#{$bpAbbv}-');
    }
}