@use '../../abstracts/' as *;

.event-detail {
    border-bottom: 2px solid $color-blue;

    .breadcrumbs {
        margin-bottom: clamp(40px, 4.4vw, 80px);
        color: var(--color-light-sky);
        padding-top: 15px;
        position: relative;
        z-index: 1;
    }
}

.event-detail__heading {
    background: $color-blue;
    position: relative;
    padding-bottom: 1rem;
    
    &:after {
        @include pseudo();
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('/dist/images/texture.png') no-repeat center center;
        background-size: cover;
    }
}

.event-detail__title {
    color: $color-white;
    margin: 0;
    position: relative;
    z-index: 1;
}

.event-detail__left,
.event-detail__right {
    padding: 2rem;
    font-family: $font-secondary;
}

.event-detail__left {
    background: $color-off-white;
}

.event-detail__right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.event-detail__time {
    margin-bottom: 1rem;
}

.event-detail__label {
    margin-bottom: 0;
    @include x-rem(font-size, 18px);
}

.event-detail__field {
    word-break: break-word;
}

.event-detail__address {
    margin-bottom: 1rem;
    font-style: normal;
}

.event-detail__img {
    margin-bottom: 1rem;
}

.event-detail__content {
    font-family: $font-secondary;
    margin-bottom: 2rem;
}

.event-detail__link {
    display: block;
    margin-top: auto;
}

@media(min-width: $bp-l) {
    .event-detail__left,
    .event-detail__right {
        padding: 2rem 2rem 6rem 2rem;
        min-height: 440px;
    }

    .event-detail__left {
        width: 33.333333%;
    }

    .event-detail__right {
        width: 66.666666%;  
    }
}