@use '../../abstracts/' as *;
.interior-heading-wrap  {

    .product-search {
        position:relative;
        z-index:2;
        margin-top:-20px;
        margin-bottom:0;
    }

    @media(min-width:$bp-l){
        .product-search {
            margin-top:-50px;
            margin-bottom:-50px;
        }
    }
}

.interior-heading {
    position: relative;
    z-index:1;
    background: radial-gradient(110.72% 108.34% at -3.92% -8.98%, #006EB8 0%, #005288 31.39%, #003152 94.9%);
    text-align: center;

    &:after {
        @include pseudo();
        top:0;
        left:0;
        width:100%;
        height:100%;
        background:url('/dist/images/texture.png') no-repeat center center;
        background-size:cover;
        z-index: -1;
    }

    &:has(.interior-heading__media) {
        text-align: left;
    }
}

.interior-heading__text {
    padding:clamp(20px, 3.6vw, 70px) 20px 20px;

    &:has(.breadcrumbs) {
        padding-top:15px;

        .breadcrumbs {
            margin-bottom:clamp(40px, 4.4vw, 80px);
            color:var(--color-light-sky);
        }
    }
}

.interior-heading__content {
    font-size:clamp(1.125rem, 1.25vw, 1.5rem);
}

.interior-heading__media {
    margin:40px 0 0;
}

.interior-heading__img {
    width:100%;
}

@media(min-width:$bp-mw){
    .interior-heading {

        &:has(.interior-heading__media) {
            display: grid;
            grid-template-columns: 1fr 45%;

            .interior-heading__text {
                padding-bottom:clamp(60px, 6.3vw, 122px);
                padding-right:25px;
            }
        }
    }

    .interior-heading__text {
        padding-top:clamp(20px, 3.6vw, 70px);
        padding-bottom:clamp(60px, 6.3vw, 90px);
        padding-inline:clamp(80px, 8.3vw, 160px);
    }

    .interior-heading__media {
        position: relative;
        display: block;
        overflow: hidden;
        margin:0;

        .interior-heading__img {
            position: absolute;
            height: 100%;
            max-width: none;
            width:auto;
            left:0;
            object-fit: cover;
        }
    }
}

@media(min-width:120em){
    .interior-heading__text {
        padding-inline:clamp(80px, 8.3vw, 160px);
    }

    .interior-heading__media {

        .interior-heading__img {
            right: 0;
            left:auto;
        }
    }
}