@use '../abstracts/' as *;

.util-nav__menu,
.util-nav__sub {
    @extend %listreset;
}

.util-nav__sub {
    display: none;
}

.util-nav__toggle {
    @extend %buttonreset;
}

@media(min-width:$bp-l){
    .util-nav__menu {
        display: flex;
        align-items: center;
        gap:25px;
    }

    .util-nav__item {
        position:relative;
    }

    .util-nav__link,
    .util-nav__toggle {
        font-family: var(--font-secondary);
        font-size:14px;
        font-weight:$fontWeight-medium;
        text-decoration: none;
        color:var(--color-dark-blue);
    }

    .util-nav__toggle {
        display: inline-flex;
        align-items: center;
        gap:6px;

        &:after {
            content: '';
            display: block;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid currentColor;
        }
    }

    .util-nav__sub {
        position:absolute;
        top:100%;
        left:0;
        min-width:100%;
        gap:12px;
        flex-direction: column;
        padding:12px;
        white-space: nowrap;
        border-radius: 8px;
        background: $color-white;
        box-shadow: 0px 6px 16px 0px rgba(2, 33, 53, 0.20);
        z-index: 1;
    }
}