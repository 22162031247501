@use '../abstracts' as *;

:root {
    @each $color, $colorVal in $colors {
        --color-#{$color}:#{$colorVal};
    }
    --font-primary:#{$font-primary};
    --font-secondary:#{$font-secondary};
    --sf-backgrоund-color:transparent;
    --sf-backgrоund-image:none;
    --sf-background-size:auto;
    --sf-background-position:0 0;
}

@font-face {
    font-family: "Manrope-fallback";
    size-adjust: 102.94%;
    ascent-override: 110%;
    src: local("Arial");
}

@font-face {
    font-family: "Inter-fallback";
    size-adjust: 107.00%;
    ascent-override: 90%;
    src: local("Arial");
}

html,
button,
input,
select,
textarea {
    color: $color-base;
}

body {
    font-family: $font-primary;
    line-height: 1.4;
    overflow-y: scroll;
    background: $body-bg;
    font-optical-sizing: auto;
    font-style: normal;
}

html {
    @media(min-width:$bp-l){
        scroll-padding-top: 220px;
    }
}

::-moz-selection {
    background: $color-primary;
    color: $color-invert;
    text-shadow: none;
}

::selection {
    background: $color-primary;
    color: $color-invert;
    text-shadow: none;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    margin: 1em 0;
    padding: 0;
    border-top: 1px solid #ccc;

    &.white-short {
        border-top: 6px solid $color-white;
        max-width: 60px;
        margin: 1em auto;
    }
}

img {
    vertical-align: middle;
    height: auto;
    max-width: 100%;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.hidden {
    display: none !important;
    visibility: hidden;
}

.sr-only {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    margin: -1px;
    padding: 0;
    border: 0;
    width: 1px;
    height: 1px;
}

.sr-only.focusable:active,
.sr-only.focusable:focus {
    position: static;
    overflow: visible;
    clip: auto;
    margin: 0;
    width: auto;
    height: auto;
}

.invisible {
    visibility: hidden;
}

figure {
    margin: 1em 0;
}

iframe {
    border: 0;
    max-width:100%;
}

ol,ul {
    padding-left:25px;
    font-weight:$fontWeight-normal;
}

ul {
    list-style: disc;

    li::marker {
        font-size:15px;
    }
}

/* ================================================================
   Talon Util Boilerplate Additions
================================================================ */

*:focus:not(:focus-visible) {
    outline:0;
}

.site-main {
    line-height:1.75;
}

svg.icon {
    width:20px;
    height:20px;
    fill:currentColor;
}

.sf-Code {
    pre {
        display: block;
        padding: 9.5px;
        margin: 0 0 10px;
        font-size: 13px;
        line-height: 1.42857143;
        color: #333;
        word-break: break-all;
        word-wrap: break-word;
        background-color: #f5f5f5;
        border: 1px solid #ccc;
        border-radius: 4px;
        white-space: pre-wrap;
        color: #666;
     }
}

.skip-link {
    @extend .sr-only;
    text-align: center;

    &:focus {
        position: static;
        display: block;
        color: $color-invert;
        background: $color-primary;
        padding: 20px 0;
        height: auto;
        width: 100%;
    }
}

[data-src] {
    opacity: 0;
    transition:opacity $transition-speed ease-in-out;

    &:not(.inView) {
        display: block;
    }

    &.inView { opacity:1; }

    .no-js & { display:none; }
}

%arrow-link {
    --border-color: #47799F;

    display: inline-flex;
    align-items: center;
    gap:.5rem;
    font-weight:$fontWeight-bold;
    text-decoration: none;
    text-transform: uppercase;
    color:$color-black;

    &:after {
        @include pseudo(block, static, '');
        border: solid var(--border-color);
        border-width: 2px 2px 0 0;
        margin-top: -3px;
        padding:4px;
        transform: rotate(45deg);
    }
    
    &:hover, &:focus-visible {
        text-decoration: underline;
        color:$color-primary;
    }
}

%arrow-link-left {
    --border-color: #47799F;
    @extend %arrow-link;

    &:before {
        @include pseudo(block, static, '');
        border: solid var(--border-color);
        border-width: 2px 2px 0 0;
        margin-top: -3px;
        padding:4px;
        transform: rotate(-135deg);
    }

    &:after {
        display:none;
    }
}

.arrow-link {
    @extend %arrow-link;
    
    &[style*="color:"] {
        --border-color: currentColor;
    }

    &:has(span) {
        display:inline-block;

        &:after {
            display: none;
        }

        span {
            @extend %arrow-link;
            &[style*="color:"] {
                --border-color: currentColor;
            }
        }
    }

    &.arrow-link--left {
        @extend %arrow-link-left;

        &[style*="color:"] {
            --border-color: currentColor;
        }
    
        &:has(span) {
            display:inline-block;
    
            &:after {
                display: none;
            }
    
            span {
                @extend %arrow-link;
                &[style*="color:"] {
                    --border-color: currentColor;
                }
            }
        }
    }
}

dialog {
    border:0;
    border-radius: 10px;
    padding:clamp(20px, 4.1vw, 80px);
    background:$color-white;
    overflow: visible;

    &::backdrop {
        opacity: .8;
        background:$color-primary;
    }

    button {
        position:absolute;
        top:-25px;
        right:0;
    }
}