@use '../abstracts' as *;

h1, .h1, 
h2, .h2, 
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	margin-top:0;
	margin-bottom:$typography-margin-bottom;
	font-weight:$fontWeight-semibold;
    line-height:1.4;
	color:var(--color-heading);
}

h1, .h1 { font-size: clamp(2rem, 2.3vw, 2.875rem); }
h2, .h2 { font-size: clamp(1.625rem, 1.87vw, 2.25rem); }
h3, .h3 { font-size: clamp(1.25rem, 1.25vw, 1.5rem); }
h4, .h4 { font-size: clamp(1rem, 1.04vw, 1.25rem); }
h5, .h5 { font-size: clamp(.75rem, .83vw, 1rem); font-family:var(--font-secondary); }
h6, .h6 { font-size: clamp(0.5rem, .72vw, 0.875rem); font-family:var(--font-secondary); }
.underline:after {
	@include pseudo(block, static, '');
	margin-top:.83em;
	width:60px;
	height:6px;
	background:$color-secondary;
}

[style~="text-align:center"] {
	.underline:after {
		margin-left:auto;
		margin-right:auto;
	}
}

[style~="text-align:right"] {
	.underline:after {
		margin-left:auto;
	}
}

.eyebrow {
	font-size:clamp(1rem, .93vw, 1.125rem);
	font-family: var(--font-secondary);
	font-weight:$fontWeight-semibold;
	text-transform: uppercase;
	letter-spacing: 0.0313rem;
	color:var(--color-burnt-orange);
}


a {
	font-family: var(--font-secondary);
	font-weight:$fontWeight-extra-bold;
	text-decoration: none;
	color:var(--color-link);
	&:hover, 
	&:focus-visible { 
		text-decoration:underline; 
	}
}

.cta-link {
	@extend %buttonreset;
	padding-block-end: 10px;
	background-image: linear-gradient(transparent calc(1% - 2px), $color-secondary 0);
    background-repeat: no-repeat;
    background-size: 100% 2px;
    background-position: 0 100%;
	text-decoration: none;
	line-height: 1;

	&:hover, 
	&:focus-visible { 
		text-decoration: none;
		color:$color-secondary;
	}

	&.left-arrow,
	&.right-arrow {
		padding-top: 8px;
		padding-bottom: 10px;
	}
}

p {
    margin:0 0 20px;

	&:last-child {
		margin-bottom:0;
	}
}

blockquote {
	display: grid;
	grid-template-columns: auto auto;
	column-gap:50px;
	border-radius: 8px;
	margin:0;
	padding:30px 40px 30px 65px;
	background: var(--color-white);
	box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);
	position: relative;

	&:before {
		content: '\201D';
		display: block;
		font-size: 6rem;
		color: $color-dark-blue;
		position: absolute;
		top: -1rem;
		left: 1rem;
	}
}

@container (width < 41.25em) {
	blockquote {
		grid-template-columns: auto;
		column-gap:0;
	}
}

.right-arrow {
	position: relative;
	padding-right: 1.25rem;

	&:before {
		content: '';
		display: block;
		width: 12px;
		height: 2px;
		background: currentColor;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
	}

	&:after {
		content: '';
		display: block;
		width: 6px;
		height: 6px;
		border-right: 2px solid currentColor;
		border-bottom: 2px solid currentColor;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%) rotate(-45deg);
	}
}

.left-arrow {
	position: relative;
	padding-left: 1.25rem;

	&:before {
		content: '';
		display: block;
		width: 12px;
		height: 2px;
		background: currentColor;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}

	&:after {
		content: '';
		display: block;
		width: 6px;
		height: 6px;
		border-left: 2px solid currentColor;
		border-bottom: 2px solid currentColor;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%) rotate(45deg);
	}
}
