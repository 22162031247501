@use '../abstracts' as *;

.breadcrumbs--post-detail {
    margin-bottom: clamp(40px, 4.4vw, 80px);
    color: var(--color-dark-blue);
    padding-top: 15px;
    position: relative;
    z-index: 1;
}

.breadcrumbs-wrap {
    position: relative;
    z-index: 1;
    padding-block:15px;
    background: radial-gradient(110.72% 108.34% at -3.92% -8.98%, #006EB8 0%, #005288 31.39%, #003152 94.9%);
    color:var(--color-light-sky);

    &:after {
        @include pseudo();
        top:0;
        left:0;
        width:100%;
        height:100%;
        background:url('/dist/images/texture.png') no-repeat center center;
        background-size:cover;
        z-index:-1;
    }
}

.breadcrumbs__list {  
    @extend %listreset;
    display: flex;
    flex-wrap: wrap;
    gap:.5rem;
}

.breadcrumbs__item { 
    display:inline-flex; 
    gap:.5rem;
    text-transform: uppercase;
    font-size:0.75rem;

    &:after {
        content:'/'; 
        display:block;
    }
    
    &:last-child {
        &:after { display:none; }
    }
}

.breadcrumbs__link {
    font-weight:$fontWeight-medium;
    text-transform: uppercase;
    color: currentColor;
}
