@use '../../../node_modules/tiny-slider/dist/tiny-slider.css';
@use '../abstracts/' as *;

.tns-outer {
  position:relative;
}

.tns-slider {
    display: flex;
    overflow: hidden;

    .lazy-img {
        opacity: 1;
    }
}

.tiny-slider-ctrls {
    display: flex;
    justify-content: space-between;
    width:100%;
}

.tns-controls button {
    --arrow-radius:50%;
    --arrow-size:50px;
    --arrow-border-color:transparent;
    --arrow-color:#{$color-white};
    --arrow-bg-color:#{$color-orange};
    --arrow-hover-bg-color:#{$color-orange};
    @extend %buttonreset;
    position:absolute;
    top:50%;
    z-index:10;
    border:1px solid var(--arrow-border-color);
    border-radius: var(--arrow-radius);
    width:var(--arrow-size);
    height:var(--arrow-size);
    background:var(--arrow-bg-color);
    color: transparent;
    overflow: hidden;
    transform:translateY(-50%);
    transition: background .3s ease-in-out;

    &:before {
        @include pseudo();
        top:19px;
        border:solid var(--arrow-color);
        border-radius: 2px;
        padding:4px;
        transform:rotate(45deg);
        transition:border-color .3s ease-in-out;
    }

    &[data-controls="prev"] {
      left:0;
      &:before {
        left:21px;
        border-width:0 0 3px 3px;
      }
    }

    &[data-controls="next"] {
      right:0;

      &:before {
        right:19px;
        border-width:3px 3px 0 0;
      }
    }

    &:hover,
    &:focus-visible {
      --arrow-color:#{$color-white};
      border-color:var(--arrow-hover-bg-color);
      background:var(--arrow-hover-bg-color);
    }

    .dark-blue-watermark & {
      --arrow-border-color:rgba(0, 82, 136, 0.40);
      --arrow-bg-color:rgba(0, 0, 0, 0.16);
      --arrow-color:#{$color-orange};
      &:hover,
      &:focus-visible {
        --arrow-color:#{$color-white};
        --arrow-hover-bg-color:#{$color-orange};
      }
    }

    &[disabled] {
      opacity:.3;
    }
}

.tns-nav,
.tiny-slider-dots {
    display: flex;
    gap:14px;
    align-items: center;
    justify-content: center;
    margin-top:50px;
}

.tns-nav button {
    --dot-size:15px;
    --dot-color:#{$color-blue};
    --dot-opacity:1;
    --dot-radius:50%;
    --dot-border-width:2px;
    @extend %buttonreset;
    width:var(--dot-size);
    height:var(--dot-size);
    border-radius:var(--dot-radius);
    border:var(--dot-border-width) solid var(--dot-color);
    background:transparent;
    transition:background .3s ease-in-out;

    &:hover,
    &:focus-visible,
    &.tns-nav-active {
        background: var(--dot-color);
    }

    .dark-blue-watermark & {
      --dot-color:#{$color-orange};
        &:hover,
        &:focus-visible,
        &.tns-nav-active {
            --dot-color:#{$color-white};
        }
    }
}

.carousel-wrap .tns-outer {
  position: static;
}

@media(min-width:$bp-l){
  .carousel-wrap {
    position:relative;
    padding:0 100px;
  }
}

@media(min-width:100em){
  .carousel-wrap {
    padding:0;

    .tns-controls {
      [data-controls="prev"] {
        left:-100px;
      }
  
      [data-controls="next"] {
        right:-100px;
      }
    }
  }
}