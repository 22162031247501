@use '../../abstracts/' as *;

.product-search__form {
    position: relative;
    z-index:1;
    display: flex;
    flex-direction: column;
    gap:10px;
    max-width:1600px;
    margin:0 20px -20px;
    border-radius: 8px;
    padding-block:40px;
    padding-inline:clamp(20px, 2vw, 40px);
    box-shadow: 0px 6px 24px 0px rgba(2, 33, 53, 0.06);
    background:var(--color-white);
}

.product-search__text {
    margin-bottom:10px;
    font-size:clamp(1.625rem, 1.8vw, 2.25rem);
    font-weight:$fontWeight-semibold;
    line-height:1.4;
    color:var(--color-dark-blue);
}

.product-search__main .product-list--match {
    margin:0;
}

@media(min-width:$bp-mw){
    .product-search__form {
        margin-bottom:-80px;
        flex-direction: row;
        align-items: flex-end;
        gap:24px;
        margin-inline:auto;
        box-shadow: 0px 6px 24px 0px rgba(2, 33, 53, 0.06);
    }

    .product-search__text {
        margin:0;
        white-space: nowrap;
    }

    .form-group {
        flex-grow:1;
        margin:0;
    }

    .product-search__submit {
        width:140px;
    }
}