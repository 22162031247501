@use '../abstracts' as *;

table {
    width:100%;
    max-width:100%;
    margin-bottom:16px;
    border-collapse: collapse;
}

th {
    background:$color-primary;
    color:$color-white;
}

td, th {
    padding:10px;
    border-bottom:1px solid $color-light-gray;
}