@use '../../abstracts' as *;

.standard-card {
    position: relative;
    background: $color-white;
    overflow: hidden;
}

.standard-card__title {
    @extend %item__title;
}